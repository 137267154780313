#loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}
#loader {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #3498db;
  -webkit-animation: spin 2s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 2s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
  z-index: 1001;
}

#loader:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #e74c3c;
  -webkit-animation: spin 3s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 3s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}

#loader:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #f9c922;
  -webkit-animation: spin 1.5s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 1.5s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}

@-webkit-keyframes spin {
  0%   {
    -webkit-transform: rotate(0deg);  /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(0deg);  /* IE 9 */
    transform: rotate(0deg);  /* Firefox 16+, IE 10+, Opera */
  }
  100% {
    -webkit-transform: rotate(360deg);  /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg);  /* IE 9 */
    transform: rotate(360deg);  /* Firefox 16+, IE 10+, Opera */
  }
}
@keyframes spin {
  0%   {
    -webkit-transform: rotate(0deg);  /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(0deg);  /* IE 9 */
    transform: rotate(0deg);  /* Firefox 16+, IE 10+, Opera */
  }
  100% {
    -webkit-transform: rotate(360deg);  /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg);  /* IE 9 */
    transform: rotate(360deg);  /* Firefox 16+, IE 10+, Opera */
  }
}

#loader-wrapper .loader-section {
  position: fixed;
  top: 0;
  width: 51%;
  height: 100%;
  background: #222222;
  z-index: 1000;
}

#loader-wrapper .loader-section.section-left {
  left: 0;
}

#loader-wrapper .loader-section.section-right {
  right: 0;
}

/* Loaded */
.loaded #loader-wrapper .loader-section.section-left {
  -webkit-transform: translateX(-100%);  /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: translateX(-100%);  /* IE 9 */
  transform: translateX(-100%);  /* Firefox 16+, IE 10+, Opera */
}

.loaded #loader-wrapper .loader-section.section-right {
  -webkit-transform: translateX(100%);  /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: translateX(100%);  /* IE 9 */
  transform: translateX(100%);  /* Firefox 16+, IE 10+, Opera */
}

.loaded #loader {
  opacity: 0;
}

.loaded #loader-wrapper {
  visibility: hidden;
}

.loaded #loader {
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.loaded #loader-wrapper .loader-section.section-right,
.loaded #loader-wrapper .loader-section.section-left {

  -webkit-transition: all 0.3s 0.3s ease-out;
  transition: all 0.3s 0.3s ease-out;
}

.loaded #loader-wrapper {
  //-webkit-transform: translateY(-100%);
  //-ms-transform: translateY(-100%);
  //transform: translateY(-100%);
  opacity: 0;
  -webkit-transition: opacity 0.3s 0.6s ease-out;
  transition: opacity 0.3s 0.6s ease-out;
}

.preload-screen {
  background-color: #fff;
  z-index: 9999;
  opacity: 1;
  visibility: visible;
  transition: opacity .35s ease, visibility .35s ease;
  overflow: hidden;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  height: 100vh;
  width: 100vw;
  position: fixed;
  display: flex;
  z-index: 1001;

}

.preload-screen__loading-bar {
  width: 13rem;
  height: 0.2rem;
  border-radius: 0.2rem;
  background-color: #ebebeb;
  overflow: hidden;
  position: relative;
  display: block;
}

.preload-screen__loading-bar::after {
  content: "";
  height: 100%;
  width: 6.8rem;
  position: absolute;
  transform: translate(-3.4rem);
  background-color: #212326;
  border-radius: 0.2rem;
  animation: initial-loading 1s ease infinite;
}

@keyframes initial-loading {
  0% {
    transform: translate(-6.8rem);
  }


  100% {
    transform: translate(13rem);
  }
}