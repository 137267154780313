// Base Quick Add Button Styles
.module-quick-add__add-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1em;
  width: 100%;
  position: absolute;
  
  // Visual styles
  background: rgba(255, 255, 255, .9) !important;
  padding: 1em;
  box-sizing: border-box;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.3);
  

  // Nested elements
  button {
    width: 100%;
  }

  .addto-links-icons.addto-onimage {
    opacity: 1 !important;
    position: initial;
  }
}

// Quick Add Actions Container
.module-quick-add__actions {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
  // Visual styles
  visibility: hidden;
  background: rgba(255, 255, 255, 0.9);
  padding: 1em;
  box-sizing: border-box;

  // Inner wrapper
  .actions-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    .actions.add-cart-wrapper .qty-wrapper {
      grid-column: 1 / span 2;
      align-self: center;
      justify-self: center;
    }

    // More Info Button
    .button.more-info {
      grid-area: more-info;
      grid-column: 1 / span 2;  // Make it span all columns
      justify-self: center; // Center horizontally in the grid cell
      text-decoration: underline;
      background: none;
      border: none;
      cursor: pointer;
      color: #202020;
    }

    // Action Buttons
    .button.cancel-button,
    .button.btn-cart.add-cart-button {
      grid-area: buttons;
      align-self: end;
      display: flex;
      justify-content: center;
    }

    .button.cancel-button {
      grid-column: 1 / 2;
    }

    .button.btn-cart.add-cart-button {
      grid-column: 2 / 3;
    }
  }

  
}

// Cart Actions Layout
.module-quick-add__actions,
// .module-hot-deals__actionButtons 
{
  .actions.add-cart-wrapper {
    // display: grid;
    // gap: 1rem;
    // justify-content: initial;
    // width: 100%;
    // height: 100%;
    // margin: 0;
    
    // grid-template-areas:
    //   "qty"
    //   "more-info"
    //   "buttons";
    display: grid;
    gap: 1rem;
    width: 100%;
    margin: 0;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto;
    grid-template-areas:
        "qty qty"
        "more-info more-info"
        "buttons buttons";
    padding: 0;
  }

  // Quantity Controls
  .qty-wrapper {
    grid-area: qty;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .qty-box {
    width: 40px;
    text-align: center;
  }

  .decrement-qty,
  .increment-qty {
    cursor: pointer;
    padding: 0 5px;
  }

  
}

// Responsive Styles
@media (max-width: 1024px) {
  .module-quick-add__add-button {
    position: relative;
    // padding-top: 0 !important;
    // padding-bottom: 5px !important;
    // transform: translate3d(0, 10px, 0);
  }
}

