@import "functions";



// Usage
$cb-primary-100: #{hsl_l("--cb-accent-9", "--primary-main", 40)};
$cb-primary-200: #{hsl_l("--cb-accent-9", "--primary-main", 30)};
$cb-primary-300: #{hsl_l("--cb-accent-9", "--primary-main", 20)};
$cb-primary-400: #{hsl_l("--cb-accent-9", "--primary-main", 10)};
$cb-primary-500: var(--cb-accent-9, var(--primary-main));
$cb-primary-600: #{hsl_d("--cb-accent-9", "--primary-main", 10)};
$cb-primary-700: #{hsl_d("--cb-accent-9", "--primary-main", 20)};
$cb-primary-800: #{hsl_d("--cb-accent-9", "--primary-main", 30)};
$cb-primary-900: #{hsl_d("--cb-accent-9", "--primary-main", 40)};

$cb-secondary-100: #{hsl_l("--cb-gray-9", "--secondary-main", 40)};
$cb-secondary-200: #{hsl_l("--cb-gray-9", "--secondary-main", 30)};
$cb-secondary-300: #{hsl_l("--cb-gray-9", "--secondary-main", 20)};
$cb-secondary-400: #{hsl_l("--cb-gray-9", "--secondary-main", 10)};
$cb-secondary-500: var(--cb-gray-9, var(--secondary-main));
$cb-secondary-600: #{hsl_d("--cb-gray-9", "--secondary-main", 10)};
$cb-secondary-700: #{hsl_d("--cb-gray-9", "--secondary-main", 20)};
$cb-secondary-800: #{hsl_d("--cb-gray-9", "--secondary-main", 30)};
$cb-secondary-900: #{hsl_d("--cb-gray-9", "--secondary-main", 40)};

:root {
  // reference https://getbootstrap.com/docs/5.2/customize/css-variables/#root-variables
  // --cb-primary: #65318e;
  // --cb-secondary: #6c757d;
  // --cb-success: #198754;
  // --cb-info: #0dcaf0;
  // --cb-warning: #ffc107;
  // --cb-danger: #dc3545;
  // --cb-light: #f8f9fa;
  // --cb-dark: #212529;


  // --primary: #f3f3f3;
  // --primary-dark: #aaaaaa; || e5e5e5 || ddd
  // --primary-light:#f5f5f5 || eee;

  // --secondary: #65318e;
  // --secondary-dark: #462263;
  // --secondary-light:#835aa4;

  // / a > primary > secondary


  --cb-primary-main: var(--cb-primary-500);
  --cb-primary-dark: var(--cb-accent-10, var(--cb-primary-600));
  --cb-primary-light: var(--cb-accent-9, var(--cb-primary-400));
  --cb-primary-contrast-text: var(--primary-contrast-text);
  --cb-primary-contrast-link: var(--primary-contrast-link);

  --cb-secondary-main: var(--cb-gray-12, var(--cb-secondary-500));
  --cb-secondary-dark: var(--cb-secondary-600);
  --cb-secondary-light: var(--cb-secondary-400);


  // --cb-color: #333;
  --cb-background-default: var(--cb-background, var(--background-default));
  --cb-color: var(--cb-gray-12, var(--text-primary));
  --cb-accent-color: var(--cb-accent-12);


  // --cb-link-color: #888; #999
  --cb-link-color: var(--cb-accent-11, var(--link-primary));
  --cb-link-hover-color: var(--cb-accent-12, var(--link-secondary));

  --cb-border-width: 1px;
  --cb-border-style: solid;
  --cb-border-color: var(--cb-gray-6, var(--cb-primary-dark));


  // First, ensure HSL components are available


// Pre-calculate both contrast options
--cb-accent-contrast-light: hsl(var(--cb-accent-9-h, 0 ), var(--cb-accent-9-s, 0%), 95%);  // Light text
--cb-accent-contrast-light-hover: hsl(var(--cb-accent-9-h, 0), var(--cb-accent-9-s, 0%), 80%);   // Light text

  //--cb-accent-contrast-dark: hsl(var(--cb-accent-9-h), var(--cb-accent-9-s), 20%);   // Dark text
--cb-accent-contrast-dark: var(--cb-accent-12, var(--cb-primary-main, #333));   // Dark text
--cb-accent-contrast-dark-hover: var(--cb-accent-11, var(--cb-secondary-main, #555));   // Dark text




  // Dynamic contrast selection based on lightness
--cb-accent-contrast: var(
  --cb-accent-contrast-light,#fff
);
  
  // --cb-accent-9-h: var(--cb-accent-9-h, 0);
  // --cb-accent-9-s: var(--cb-accent-9-s, 100%);
  // --cb-accent-9-l: var(--cb-accent-9-l, 50%);

  // $accent-h: var(--cb-accent-9-h);
  // $accent-s: var(--cb-accent-9-s);
  // $accent-l: var(--cb-accent-9-l);

  // --cb-accent-contrast: #{get-contrast-color($accent-h, $accent-s, $accent-l)};
  // --cb-accent-contrast-link-color: #{get-contrast-color($accent-h, $accent-s, $accent-l)};
  // --cb-accent-contrast-link-hover-color: #{get-contrast-color($accent-h, $accent-s, $accent-l)};

  // btn-primary
  // if --btn-primary-bg then use it else use --cb-primary-main
  --cb-btn-accent-bg: var(--cb-accent-9, var(--cb-primary-main));
  --cb-btn-accent-color: var(--cb-accent-contrast, var(--cb-primary-contrast-text));
  --cb-btn-accent-border: var(--btn-primary-border, var(--cb-primary-light));
  --cb-btn-accent-hover-bg: var(--cb-accent-10, var(--cb-secondary-main));
  --cb-btn-accent-hover-color: var(--cb-accent-contrast, var(--secondary-contrast-text));
  --cb-btn-accent-hover-border: var(--btn-primary-hover-border, var(--cb-primary-light));

  // btn-secondary
  --cb-btn-gray-bg: var(--btn-secondary-bg, var(--cb-secondary-main));
  --cb-btn-gray-color: var(--btn-secondary-color, var(--secondary-contrast-text));
  --cb-btn-gray-border: var(--btn-secondary-border, var(--cb-secondary-dark));
  --cb-btn-gray-hover-bg: var(--btn-secondary-hover-bg, var(--cb-secondary-dark));
  --cb-btn-gray-hover-color: var(--btn-secondary-hover-color, var(--secondary-contrast-text));
  --cb-btn-gray-hover-border: var(--btn-secondary-hover-border, var(--cb-secondary-dark));

  --color-white: #fff;
}
