// Color utility classes
.bg-accent {
  background-color: var(--cb-accent-9);
  color: var(--cb-accent-contrast);

  // For links inside accent background
  a {
    color: var(--cb-accent-contrast);
    
    &:hover {
      color: var(--cb-accent-contrast-soft, rgba(255,255,255,0.8));
    }
  }
}

.bg-white {
  background-color: #fff;
  color: var(--cb-gay-12);

  // For links inside white background
  a {
    color: var(--cb-gray-12);
    
    &:hover {
      color: var(--cb-gray-11);
    }
  }
}

// Text-only variants
.text-on-accent {
  color: var(--cb-accent-contrast);
}

.text-on-white {
  color: var(--cb-accent-12);
}