/* Footer %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */

.footer-container,
.footer-container2 { background-repeat:repeat; background-position:center top; background-attachment: scroll; }

.footer-container {
	background-color: var(--cb-accent-9, var(--cb-primary-main));
	color: var(--cb-accent-contrast, var(--cb-primary-contrast-text));
}

.footer-bottom-container {

}

/* Primary footer additional
-------------------------------------------------------------- */
.footer-primary-top-spacing {
	padding-top:20px;
	padding-bottom:20px;
	border-bottom:1px solid;
	border-color:var(--cb-gray-6, var(--cb-primary-light));
}
.footer-primary-bottom-spacing {
	border-top:1px solid;
	border-color:var(--cb-gray-6, var(--cb-primary-light));
	padding-top:20px;
	padding-bottom:20px;
}
.footer-bottom {
	padding-top:10px; /* To counterbalance the bottom margin of footer elements */
}

#subscribe-form .input-box .validation-advice {
	padding:10px 0;
	margin:0;
	width:140%;
	position:absolute;
	top:-103%;
	left:-20%;
	text-align:center;
	background-color:#fff;
	background-color:rgba(255,255,255,0.9);
	z-index:1;
}

 #footer #subscribe-form .input-box {
	 margin: 0 1%;
 }

/* Social services
-------------------------------------------------------------- */
.social-links span.icon { margin-bottom:3px; }

/* Other elements
-------------------------------------------------------------- */
.footer-copyright { font-size:0.9166em; /*11px*/ line-height:1.3636em; margin-bottom:10px; margin-left:10px; display:none; }

#footer ul.bullet li {
    background: none;
    padding-left: 0px;
    text-transform: capitalize;
    letter-spacing: 0.5px;
}

#footer .container {
    max-width: 90vw;
}
.footer-top-container {
   margin: 30px 0;
}