// =============================================================================
// Base Module Styles
// =============================================================================

// -----------------------------------------------------------------------------
// Variables
// -----------------------------------------------------------------------------
$breakpoint-mobile: 767px;
$breakpoint-desktop: 961px;

// -----------------------------------------------------------------------------
// Header Modules
// -----------------------------------------------------------------------------

// Logo Module
.module-logo {
  padding: 1em 0;
  text-align: center;
}

// Header Multi Module
.module-header-multi {
  .grid12-0 {
    margin: 0 !important;
  }
}

// -----------------------------------------------------------------------------
// Category Modules
// -----------------------------------------------------------------------------

// Sub Categories Module
.module-sub-categories {
  ul:not(:first-child) li {
    width: 100%;
    align-self: stretch !important;
  }
}

.module-sub-categories-title {
  font-size: 15px;

  span {
    text-transform: capitalize !important;
  }

  &.active {
    color: #65318e !important;
  }
}

// Category Filters
.module-category-filters {
  .block .block-title {
    border-bottom: none;
    display: none;
  }

  .std i {
    font-size: 18px;
    margin: 5px;
  }
}

// Category Misc
.module-category-misc {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .sort-by,
  .limiter {
    line-height: 38px;
  }

  .grid12-0 {
    &.item-right,
    &.right {
      &:first-of-type {
        margin-left: auto;
      }
    }

    &.item-left,
    &.left {
      &:first-of-type {
        margin-right: auto;
      }
    }
  }
}

// -----------------------------------------------------------------------------
// Product Listing Modules
// -----------------------------------------------------------------------------
.module-category-product-listing {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  flex-direction: column;

  &__productImage {
    width: 100% !important;
  }

  .quickviewbutton {
    position: absolute;
    top: 0;
    right: 0;
    left: initial;
    display: none;
  }
}

// Product List View
.category-products-listing.products-list {
  .module-category-product-listing {
    flex-direction: row;
    text-align: left;
    align-items: center;

    @media only screen and (max-width: $breakpoint-mobile) {
      flex-direction: column;
      text-align: center;
      align-items: flex-start;
    }
  }

  .item {
    @media only screen and (max-width: $breakpoint-mobile) {
      border: none;
    }
  }
}

// Quick View Button
.owl-item,
.module-hot-deals,
.module-category-product-listing {
  .quickviewbutton {
    position: absolute;
    top: 0;
    right: 0;
    left: initial;
    display: none;
  }
}

// -----------------------------------------------------------------------------
// Product Modules
// -----------------------------------------------------------------------------
.module-product {
  &-details {
    gap: 1rem 0;
  }

  &-image .more-images .slider-thumb {
    .slick-prev,
    .slick-next {
      width: var(--cb-global-icon-width);
      height: var(--cb-global-icon-height);
    }
  }

  &-image .more-images a.cloud-zoom-gallery {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

// -----------------------------------------------------------------------------
// Newsletter Module
// -----------------------------------------------------------------------------
#subscribe-form {
  .inputs {
    display: flex;
  }

  .input-text {
    width: 200px !important;
    line-height: 45px;
    height: 45px;
  }

  .button {
    text-transform: uppercase;
  }

  .newsletter-label-input_email {
    display: none;
  }
}

// -----------------------------------------------------------------------------
// Carousel Module
// -----------------------------------------------------------------------------
.xm-owl-carousel .item,
.owl-carousel .owl-item {
  .product-name a {
    white-space: inherit;
  }
}


