.header-container {
    position: sticky;
    width: 100%;
    z-index: 99;
    background-color: var(--cb-accent-9, var(--cb-primary-main));
    /* background: linear-gradient(to bottom, var(--cb-accent-3, var(--cb-primary-main)), transparent); */

    top: 0;
    color: var(--cb-accent-contrast, var(--cb-primary-contrast-text));
    /* color: light-dark(var(--cb-accent-contrast-dark),var(--cb-accent-contrast-light));
    color-scheme: normal; */
}

.header-container a{
    color: var(--cb-primary-contrast-link);
}

.grid-container,
.xm-grid-header .grid-group {
    display: flex;
    width: 100%;
    align-items: center;
    margin: 0;
    flex-wrap: wrap;
}

.xm-grid-header > .container,
.xm-grid-header > .container .nested-container {
    width: 100%;
    margin: 0;
    max-width: none;
    padding: 0;
}

/*replace user link icons*/
.links > li > a {
    text-indent: -9999px;
    position: relative;
    /* font-size: 1.5em; */
    /* padding: 0 20px; */
    line-height: 2em !important;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--cb-global-icon-height);
    width: var(--cb-global-icon-width);
    color: var(--cb-accent-contrast);
}

.links > li > a::after {
    font-family: "fontAwesome";
    text-indent: 0;
    background-position: center;
    background-repeat: no-repeat;
    /* position: absolute; */
    /* top: 0;
    left: 0; */
    width: 100%;
}

.links > li > a:hover {
    background-color: var(--cb-accent-4, var(--cb-secondary-main));
    color: var(--cb-accent-12, var(--secondary-contrast-text));
}

.links .link-logout::after {
    content: "\f08b";
}

.links .link-login::after {
    content: "\f090";
}

.links .link-register::after {
    content: "\f007";
}

.links .link-admin::after {
    content: "\f013";
}

.links .link-account::after {
    content: "\f007";
}

.links .link-wishlist::after {
    content: "\f004";
}

#nav {
    position: relative;
    display: flex;
    justify-content: center;
}

.nav-regular li.level0 > a {
    line-height: 60px;
}

.nav-mobile .nav-item.level0 > a span:hover,
.nav-regular .nav-item.level0:hover > a span {
    background-color: transparent;
    border-bottom: 2px solid var(--cb-gray-12, var(--cb-secondary-main));;
    height: 40px;
    /* color:var(--cb-primary-contrast-link); */
}

.nav-mobile .nav-item.level0 > a:hover,
.nav-regular .nav-item.level0:hover > a {
    background-color: transparent;
    color: var(--cb-accent-contrast, var(--cb-primary-contrast-text)); /*--cb-secondary-main) !important;*/
}

#nav .nav-item.level0.parent:hover > a .caret {
    border-top-color: var(--cb-secondary-main);;
    border-bottom: 0;
    height: 0;
}

.nav-item.active {
    background-color: transparent;
}

.nav-regular li.level0 {
    float: left;
    padding: 0 12px;
}

/*fixed header upon scroll*/

.nav-regular .mega > .nav-panel--dropdown {
    margin: 0;
    margin-top: -1px;
}

/*Hide header line 1 and 2 upon scroll*/
#header.sticky .header-line1,
#header.sticky .header-line2 {
    display: none;
}

/*space between nav elements*/
.mobnav-trigger,
.nav-regular li.level0 > a {
    line-height: 50px;
}

/* ================================================ */
/* Menu */
/* ================================================ */
.nav-item--home {
    display: none;
}

.nav.has-outline > .grid-full {
    margin-top: 0;
    margin-bottom: 0;
}

#nav {
    z-index: 1000;
    position: relative;
    border-bottom: none;
}

.mm-panels .sidebar.sidebar-filter.hide-below-960 {
    display: block !important;
}

.nav-regular {
    background-color: transparent;
}

#nav.nav-mobile ul.level0 .nav-item.current > a,
#nav.nav-mobile ul.level0 .nav-item > a:hover,
.cms-index-index .nav-regular .nav-item--home > a,
.nav-mobile .nav-item.level0.current > a,
.nav-mobile .nav-item.level0 > a:hover,
.nav-regular .nav-item.level0.active > a,
.nav-regular .nav-item.level0:hover > a {
    background-color: transparent;
}

.nav-mobile .nav-item.level0 > a:hover {
    color: var(--cb-secondary-main) !important;
    background-color: #fff;
}

/*.nav-item.active {*/
/*    background-color: #fff;*/
/*}*/
.nav-item.current > a ,
.nav-item.active > a {
    /*color: var(--cb-primary-dark) !important;*/
    /*border-bottom: 2px solid var(--cb-secondary-main);*/
}

/* swith customer*/
.draggable-header .highslide-header {
    border: none !important;
}

.highslide-body iframe .main-container {
    margin: 0 !important;
}

.gen-tabs,
.gen-tabs .tabs a {
    font-family: inherit;
}
/* eof swith customer*/

/* search icon */
.module-search .form-search .button-search .fa.fa-search {
    color: inherit;
    font-size: inherit;
    padding: 0;
}

.form-search button.button-search,
.module-search .form-search button.button-search {
    border: none;
}

@media only screen and (max-width: 960px) {
    .show-below-960 .module-search .form-search .input-text {
        display: none;
    }

    .show-below-960 .module-search .form-search .button-search {
        position: unset !important;
    }

    .item-search.fly-out {
        margin: 0;
    }


    .show-below-960 .item-search.fly-out .form-search .button {
        right: 10px;
        position: absolute !important;
        margin: 0;
        padding: 0;
        font-size: inherit;
        height: var(--cb-global-icon-height);
        border: none;
        min-height: var(--cb-global-icon-height);
    }

    .show-below-960 .item-search.fly-out {
        position: fixed;
        display: block !important;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 9999;
        width: 100% !important;
        background-color: #fff;
    }

    .show-below-960 .item-search.fly-out .form-search .button-search {
        right: 45px;
    }

    #header .grid-full {
        overflow: hidden;
    }

    #new-advanced-search-box .results-right .products ul li.grow {
        width: 48% !important;
        padding: 5px 0;
        text-align: center;
    }
}
/* eof search icon */

/* cart icon */
@media only screen and (max-width: 960px) {
    .item-min-cart .hide-below-960 {
        visibility: hidden;
        /* position: relative; */
        width: 0;
        height: 0;
        display: block!important;
    }

    .item-min-cart .hide-below-960  .cart-total {
        visibility: visible;
        position: absolute;
        left: 25px;
        top: -35px;
        border: none;
        background-color: var(--cb-primary-800);
        border-radius: 50%;
        color: #fff;
        width: 17px;
        height: 17px;
        text-align: center;
        font-size: 9px;
        line-height: 17px;
    }

    .item-min-cart .caret, .item-min-cart .empty {
        display: none;
    }
}

/* 
.nav-item.active > a .caret {
    border-top-color: #65318e !important;
} */

#root-wrapper a:hover span.fa.fa-shopping-cart:before {
    color: initial;
}

#customers_datatable_wrapper .btn i {
    width: auto;
    height: auto;
}


