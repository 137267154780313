/*
* 12-column grid system
*/

/* Grid container
%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
*/

/* Container */
.container {
    width: 100%;
    max-width: 960px;
    margin-left:auto;
    margin-right:auto;
    box-sizing: border-box;
}
.nested-container {
    width:100%;
    /* width:102%; */
    /* margin-left:-1%; */
}

.lte-ie8 .container {
    max-width: 1200px;
}

/* only apply to first and last grid unit in a row */
div[class^="module-"] .grid12-0:first-child,
div[class*=" module-"] .grid12-0:first-child {
    margin-left: 0;
}

div[class^="module-"] .grid12-0:last-child,
div[class*=" module-"] .grid12-0:last-child {
    margin-right: 0;
}

/* Grid units */
.grid12-1,
.grid12-2,
.grid12-3,
.grid12-4,
.grid12-5,
.grid12-6,
.grid12-7,
.grid12-8,
.grid12-9,
.grid12-10,
.grid12-11,
.grid12-12,
.grid-full, /* Alias for full-width unit */

    /* Special grid units */
.grid-col2-sidebar,
.grid-col2-main
{
    /*position:relative;*/
    display:inline;
    float:left;
    margin-left:1%;
    margin-right:1%;
}

/* Basic grid units width */
.grid12-1	{ width:6.33%; }
.grid12-2	{ width:14.66%; }
.grid12-3	{ width:23%; }
.grid12-4	{ width:31.33%; }
.grid12-5	{ width:39.67%; }
.grid12-6	{ width:48%; }
.grid12-7	{ width:56.33%; }
.grid12-8	{ width:64.67%; }
.grid12-9	{ width:73%; }
.grid12-10	{ width:81.33%; }
.grid12-11	{ width:89.67%; }
.grid12-12,
.grid-full	{ width:98%; }

/* Grid units without left gutter (margin) */
.no-left-gutter.grid12-1	{ margin-left:0; width:7.33%; }
.no-left-gutter.grid12-2	{ margin-left:0; width:15.66%; }
.no-left-gutter.grid12-3	{ margin-left:0; width:24%; }
.no-left-gutter.grid12-4	{ margin-left:0; width:32.33%; }
.no-left-gutter.grid12-5	{ margin-left:0; width:40.67%; }
.no-left-gutter.grid12-6	{ margin-left:0; width:49%; }
.no-left-gutter.grid12-7	{ margin-left:0; width:57.33%; }
.no-left-gutter.grid12-8	{ margin-left:0; width:65.67%; }
.no-left-gutter.grid12-9	{ margin-left:0; width:74%; }
.no-left-gutter.grid12-10	{ margin-left:0; width:82.33%; }
.no-left-gutter.grid12-11	{ margin-left:0; width:90.67%; }
.no-left-gutter.grid12-12,
.no-left-gutter.grid-full	{ margin-left:0; width:99%; }

/* Grid units without right gutter (margin) */
.no-right-gutter.grid12-1	{ margin-right:0; width:7.33%; }
.no-right-gutter.grid12-2	{ margin-right:0; width:15.66%; }
.no-right-gutter.grid12-3	{ margin-right:0; width:24%; }
.no-right-gutter.grid12-4	{ margin-right:0; width:32.33%; }
.no-right-gutter.grid12-5	{ margin-right:0; width:40.67%; }
.no-right-gutter.grid12-6	{ margin-right:0; width:49%; }
.no-right-gutter.grid12-7	{ margin-right:0; width:57.33%; }
.no-right-gutter.grid12-8	{ margin-right:0; width:65.67%; }
.no-right-gutter.grid12-9	{ margin-right:0; width:74%; }
.no-right-gutter.grid12-10	{ margin-right:0; width:82.33%; }
.no-right-gutter.grid12-11	{ margin-right:0; width:90.67%; }
.no-right-gutter.grid12-12,
.no-right-gutter.grid-full	{ margin-right:0; width:99%; }

/* Grid units without any gutter (margin) */
.no-gutter.grid12-1		{ margin-left:0; margin-right:0; width:8.33%; }
.no-gutter.grid12-2		{ margin-left:0; margin-right:0; width:16.66%; }
.no-gutter.grid12-3		{ margin-left:0; margin-right:0; width:25%; }
.no-gutter.grid12-4		{ margin-left:0; margin-right:0; width:33.33%; }
.no-gutter.grid12-5		{ margin-left:0; margin-right:0; width:41.67%; }
.no-gutter.grid12-6		{ margin-left:0; margin-right:0; width:50%; }
.no-gutter.grid12-7		{ margin-left:0; margin-right:0; width:58.33%; }
.no-gutter.grid12-8		{ margin-left:0; margin-right:0; width:66.67%; }
.no-gutter.grid12-9		{ margin-left:0; margin-right:0; width:75%; }
.no-gutter.grid12-10	{ margin-left:0; margin-right:0; width:83.33%; }
.no-gutter.grid12-11	{ margin-left:0; margin-right:0; width:91.67%; }
.no-gutter.grid12-12,
.no-gutter > .grid-full,
.no-gutter.grid-full	{ margin-left:0; margin-right:0; width:100%; }

/* Special grid units: basic structure of pages with 1 and 2 sidebars
-------------------------------------------------------------- */
/* 2 columns: fixed-width sidebar unit and fluid main unit.
   Sidebar unit has the same width for all screen sizes equal or greater than 1280px.
   Width of the main unit is fluid.
*/
.grid-col2-sidebar	{ width:23%; }
.grid-col2-main		{ width:73%; }

/* Another 2 columns inside ".grid-col2-main": fixed-width sidebar unit and fluid main unit */
.grid-col3-sidebar 		{ width:31.52%; }
.grid-col3-main			{ width:65.90%; }

/* Vertically aligned grid
-------------------------------------------------------------- */
.v-grid-container {
    display:table;
    width:100%;
}
.v-grid {
    float:none;
    display:table-cell;
    vertical-align:middle;
}

/* Utility classes
-------------------------------------------------------------- */
/* Units with no gutter (margin): alpha - first unit in a row, omega - last unit in a row */
.alpha { margin-left:0; }
.omega { margin-right:0; }

/* Hide element if screen width is lower than the width specified in the class name */
.hide-below-1680 { display:none !important; }
.hide-below-1440 { display:none !important; }
.hide-below-1360 { display:none !important; }
.hide-below-1280 { display:none !important; }

/* Container: stretched, without side margin
-------------------------------------------------------------- */
/* Remove padding from stretched container (it is added for narrow screens via media queries) */
.container.stretched { width:96%; padding-left:0; padding-right:0; }

/* Stretch inner grid unit (full width) */
.container.stretched > .grid-full { width:100%; margin-left:0; margin-right:0; }

/* Grid units container: clears floats
   (place grid units inside "div" with this class)
-------------------------------------------------------------- */
.grid-container { *zoom: 1; clear: both; }
.grid-container:before,
.grid-container:after { display: table; line-height: 0; content: ""; }
.grid-container:after { clear: both; }

/* Grid unit container: clears floats and adds vertical margin between units
-------------------------------------------------------------- */
.grid-container-spaced { *zoom: 1; clear: both; }
.grid-container-spaced:before,
.grid-container-spaced:after { display: table; line-height: 0; content: ""; }
.grid-container-spaced:after { clear: both; }
.grid-container-spaced .grid12-1,
.grid-container-spaced .grid12-2,
.grid-container-spaced .grid12-3,
.grid-container-spaced .grid12-4,
.grid-container-spaced .grid12-5,
.grid-container-spaced .grid12-6,
.grid-container-spaced .grid12-7,
.grid-container-spaced .grid12-8,
.grid-container-spaced .grid12-9,
.grid-container-spaced .grid12-10,
.grid-container-spaced .grid12-11,
.grid-container-spaced .grid12-12,
.grid-container-spaced .grid-full
{
    margin-bottom: 2%;
}

/**
 * WARNING: do not edit this file. It was generated automatically, your changes will be overwritten and lost.
 * 2014-07-02 17:24:51 */

/* fix for content position align issue on grid column */
.grid-column-wrapper .grid-full {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin: 0;
}

.grid-column-wrapper.right .grid-full {
    justify-content: flex-end;
}

/**
 * Itemgrid
 */
.category-products-listing.products-list .item,
.itemgrid .item {
    float:left;
}


/* Itemgrid: different numbers of columns
%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
*/
/* Clear every first item in each row (row: group of n items) */
.itemgrid .item {
    box-sizing: border-box;
}
/* Item grid: 1 column */
.itemgrid-1col .item { width:100%; }

/* Item grid: 2 columns */
.itemgrid-2col .item { width:50%; }
.itemgrid-2col .item:nth-child(2n+1) { clear:left; }

/* Item grid: 3 columns */
.itemgrid-3col .item { width:33.3333%; }
.itemgrid-3col .item:nth-child(3n+1) { clear:left; }

/* Item grid: 4 columns */
.itemgrid-4col .item { width:25%; }
.itemgrid-4col .item:nth-child(4n+1) { clear:left; }
.itemgrid-4col .item.dop { width:48%; }

/* Item grid: 5 columns */
.itemgrid-5col .item { width:20%; }
.itemgrid-5col .item:nth-child(5n+1) { clear:left; }

/* Item grid: 6 columns */
.itemgrid-6col .item { width:16.6666%; }
.itemgrid-6col .item:nth-child(6n+1) { clear:left; }

/* Item grid: 7 columns */
.itemgrid-7col .item { width:14.2857%; }
.itemgrid-7col .item:nth-child(7n+1) { clear:left; }

/* Item grid: 8 columns */
.itemgrid-8col .item { width:12.5%; }
.itemgrid-8col .item:nth-child(8n+1) { clear:left; }