// =============================================================================
// Category Styles
// =============================================================================

// -----------------------------------------------------------------------------
// Variables
// -----------------------------------------------------------------------------
$breakpoint-mobile: 767px;
$breakpoint-desktop: 961px;

// -----------------------------------------------------------------------------
// Grid Layout
// -----------------------------------------------------------------------------
.xm-grid-category {
  .grid-column-wrapper {
    &.right,
    &.left {
      div:first-of-type {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }
    }

    &.right div:first-of-type {
      justify-content: flex-end;
    }
  }
}

// -----------------------------------------------------------------------------
// Product Listing
// -----------------------------------------------------------------------------
.category-products-listing {
  //overflow: hidden;
  &.products-list {
    .item {
      @media only screen and (min-width: $breakpoint-desktop) {
        width: 100%;
      }
    }
  }

  &.products-line {
    .products-line__labels:last-of-type {
      .module-category-product-listing {
        ul {
          border-bottom: none;
        }
      }
    }
    .module-category-product-listing {
      ul {
        width: 100%;
        border-bottom: 1px solid var(--cb-accent-7); 
        padding: 1em;
        box-sizing: border-box;
        text-align: left;
      }
    }
  }
}

// Product Image
.module-category-product-listing {
  // Add to Cart
  .actions.add-cart-wrapper {
    display: grid;
    // grid-template-columns: 1fr 1fr;
    // grid-template-rows: auto auto;
    // width: 100%;
    gap: 1em;
    justify-content: center;
    .qty-wrapper {
      grid-column: 1 / span 2;
      align-self: center;
      justify-self: center;
    }
  } 

}

// Product Image Hover
.product-image-wrapper {
  .product-image {
    img.alt-img {
      opacity: 0;
    }
  }
}

// -----------------------------------------------------------------------------
// Sorting and Pagination
// -----------------------------------------------------------------------------
.pager,
.sorter {
  line-height: initial;
  padding: initial;
}

.pagination {
  display: flex;
  align-items: center;
  padding: 1em 0;
}

// View Mode
.module-category-misc {
  .sorter {
    .view-mode {
      display: flex;
      align-items: center;

      // View Mode Options
      &-option {
        display: flex;
        align-items: center;
        text-align: center;
        color: var(--cb-accent-12, var(--cb-primary-main));

        // Font Awesome Icons
        .grid::before { content: "\f00a"; }
        .list::before { content: "\f00b"; }
        .line::before { content: "\f0c9"; }
        .variants::before { content: "\f00b"; }

        .grid,
        .list,
        .line,
        .variants {
          &::before {
            font-family: "FontAwesome";
          }
        }
      }
    }
  }
}

// Reset View Mode Styles
.sorter .view-mode {
  .grid,
  .list,
  .line,
  .variants {
    text-indent: 0;
    background: none;
    margin-left: 0;
  }
}

// -----------------------------------------------------------------------------
// Quick View
// -----------------------------------------------------------------------------
.quickviewbutton {
  @media only screen and (max-width: $breakpoint-desktop) {
    display: none !important;
  }
}

.sidebar-wrapper .module-category-filters i.fa {
  width: auto;
  height: auto;
}