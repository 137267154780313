// Basket Summary Module
.module-basket-summary {
    .dropdown-menu {
      color: var(--cb-color);
    }
  
    .caret {
      margin-right: 1em;
    }
  
    &__cart-totals {
        display: none;
      padding: 1em 0;
  
      .preview-cart-total {
        display: grid;
        grid-auto-flow: column;
  
        &-label,
        &-value {
          font-weight: 600;
          font-size: 1.1em;
          padding: 0.3em 0;
        }
  
        &-value {
          text-align: right;
        }
  
        &.ship-total {
          .preview-cart-total-value {
            font-size: var(--cb-global-font-size);
            font-weight: 400;
            color: var(--cb-gray-9);
            text-align: left;
          }
        }
      }
      .preview-cart-total.total {
        .preview-cart-total-value {
            font-size: 1.5em;
        }
      }
      
    }
  }