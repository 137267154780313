
/* Desktop - design for a width of 1280px
%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
*/

/* 1280px <= width */

@media only screen and (min-width: 1280px) {

    .container {
        max-width: 1200px;
    }

    /* Special grid units */
    .grid-col2-sidebar {
        width: 20.41%;
    }

    .grid-col2-main {
        width: 75.58%;
    }

    .grid-col3-sidebar {
        width: 27.01%;
    }

    .grid-col3-main {
        width: 70.34%;
    }
}



/* Tablet (Portrait) - design for a width of 768px
%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
*/

/* 768px <= width < 960px */
@media only screen and (min-width: 768px) and (max-width: 959px) {

    .container {
        max-width: 737px;
        width: 92%;
        padding-left: 2%;
        padding-right: 2%;
    }

    .grid12-1,
    .grid12-2,
    .grid12-3,
    .grid12-4,
    .grid12-5,
    .grid12-6,
    .grid12-7,
    .grid12-8,
    .grid12-9,
    .grid12-10,
    .grid12-11,
    .grid12-12,
    .grid-full,
    .grid-col2-sidebar,
    .grid-col2-main,
    .grid-col3-sidebar,
    .grid-col3-main {
        width: 100% !important; /* Added "!important" to override units with "no-gutter" */
        margin-left: 0;
        margin-right: 0;
    }
}



/* All mobile - design for a width of less than 768px
%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
*/

/* width < 768px */
@media only screen and (max-width: 767px) {

    /* Grid units below 768px screen size are 100% wide with no margin. Padding compensates lack of margin. */
    .container { width:92%; padding-left:2%; padding-right:2%; }
    .nested-container { width:100%; margin-left:auto; }

    /* Nav container with outline (if nav container has background color).
       Apply the same vertical margins, as container's side padding. */
    .nav.has-outline > .grid-full { margin-top: 2%; margin-bottom: 2%; }

    /* Below 768px all grid units are stretched (full width) and stack vertically */
    .grid12-1,
    .grid12-2,
    .grid12-3,
    .grid12-4,
    .grid12-5,
    .grid12-6,
    .grid12-7,
    .grid12-8,
    .grid12-9,
    .grid12-10,
    .grid12-11,
    .grid12-12,
    .grid-full,

    .grid-col2-sidebar,
    .grid-col2-main,
    .grid-col3-sidebar,
    .grid-col3-main
    {
        width:100% !important; /* Added "!important" to override units with "no-gutter" */
        margin-left:0;
        margin-right:0;
    }

    /* Vertically aligned grid
    -------------------------------------------------------------- */
    .v-grid-container { display:block; }
    .v-grid { float:inherit; display:block; width:100%; }
}





/* Mobile (Landscape) - design for a width of 480px
%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
*/

/* 480px <= width < 768px */
@media only screen and (min-width: 480px) and (max-width: 767px) {

    .container { max-width:440px; }


    /* Deprecated: persistent grid units (will be removed in the next update)
    -------------------------------------------------------------- */
    /* Half */
    .persistent-grid2-1 { width:48%; margin-left:1%; margin-right:1%; }

    /* One-third + two-thirds */
    .persistent-grid3-1 { width:31.33%; margin-left:1%; margin-right:1%; }
    .persistent-grid3-2 { width:64.67%; margin-left:1%; margin-right:1%; }

    /* One-fourth + two-fourths + three-fourths */
    .persistent-grid4-1 { width:23%; margin-left:1%; margin-right:1%; }
    .persistent-grid4-2 { width:48%; margin-left:1%; margin-right:1%; }
    .persistent-grid4-3 { width:73%; margin-left:1%; margin-right:1%; }

    /* Mobile grid (for browser viewport between 480px and 768px):
       Below 768px all grid units are stretched (full width) and stack vertically.
       The "mobile-grid" class forces narrow grid units to stay narrow,
       but the width is increased to avoid too small columns.
    -------------------------------------------------------------- */
    .mobile-grid.grid12-1 { width:14.66% !important;	margin-left:1%; margin-right:1%; } /* 1/12 turns into 1/6 */
    .mobile-grid.grid12-2 { width:31.33% !important;	margin-left:1%; margin-right:1%; } /* 1/6  turns into 1/3 */
    .mobile-grid.grid12-3 { width:48% !important;		margin-left:1%; margin-right:1%; } /* 1/4  turns into 1/2 */

    /* Force other grid units to take half of the available width */
    .mobile-grid-half { width:48% !important; margin-left:1%; margin-right:1%; }

}





/* Mobile (Landscape, iPhone 4 Portrait) - design for a width of 640px
%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
*/
/* 640px <= width < 768px */
@media only screen and (min-width: 640px) and (max-width: 767px) {

    .container { max-width:590px; }
}





/* Design for a width of less than 480px (small mobile)
%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
*/

/* width < 480px */
@media only screen and (max-width: 479px) {

    /* Stretch containers to full width and add side padding */
    .container { max-width:none !important; width:94%; padding-left:3%; padding-right:3%; }

    /* Nav container with outline (if nav container has background color).
       Apply the same vertical margins, as container's side padding. */
    .nav.has-outline > .grid-full { margin-top: 3%; margin-bottom: 3%; }

}










/* Mobile (Portrait) - design for a width of less than 320px
%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
*/

/* width < 320px */
@media only screen and (max-width: 319px) {

    /* .container { max-width:226px; } */

    .container { max-width:none !important; width:92%; padding-left:4%; padding-right:4%; }

    /* Nav container with outline (if nav container has background color).
       Apply the same vertical margins, as container's side padding. */
    .nav.has-outline > .grid-full { margin-top: 4%; margin-bottom: 4%; }
}


/**
 * WARNING: do not edit this file. It was generated automatically, your changes will be overwritten and lost.
 * To override or extend default CSS styles use custom.css file (refer to the user guide for details).
 * 2014-07-11 22:15:22 */























/* Desktop, laptop - design for a width of 1280px
%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
*/

/* 1280px <= width */

@media only screen and (min-width: 1280px)  {

    /* Header
    -------------------------------------------------------------- */
    /*.header .logo { max-width:350px; }*/

    /* Cart
    -------------------------------------------------------------- */
    .cart-table .product-image { max-width:110px; }

    /* Product page
    -------------------------------------------------------------- */
    .product-view .sticker { width:80px; height:80px; }

    /* Misc
    -------------------------------------------------------------- */
    /* Mini cart */
    .after-mini-cart { clear:none; }

    /* Cloud zoom
    -------------------------------------------------------------- */
    .itemslider-thumbnails.count-4,
    .itemslider-thumbnails.count-5,
    .itemslider-thumbnails.count-multi { max-width:316px; } /*4*/

    /* Direction navigation */
    .itemslider-thumbnails.count-4 .direction-nav { display:none; }

    /* Slideshow caption
    -------------------------------------------------------------- */
    .caption .heading { font-size:3.3em; }
    .caption p { font-size:1.6666em; }

}






/* Design for a width of 960-1280 px
%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
*/

/* 960px <= width < 1280px */
@media only screen and (min-width: 960px) and (max-width: 1279px) {

    /* Cart
    -------------------------------------------------------------- */
    .cart-table .product-image { max-width:80px; }

    /* Product page
    -------------------------------------------------------------- */
    .product-view .sticker { width:80px; height:80px; }
}





/* Mobile, small old desktop - design for a width of less than 960px
%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
*/

/* width < 960px */
@media only screen and (max-width: 959px) {

    /* Products grid: override size of elements (values like size-s)
    -------------------------------------------------------------- */
    /* Product labels */
    .products-grid .sticker { width:45px; height:45px; font-size:1em; }
    .products-grid .item:hover .sticker { width:50px; height:50px; }

    /* Misc
    -------------------------------------------------------------- */
    /* Scroll to top button */
    #scroll-to-top { display:none !important; }

    /* Hide Sign Up link in Top Links */
    #link-sign-up { display:none; }

    /* Hide amount. Note: has to be defined after aligning ".amount" center */
    .toolbar .sorter .amount { display:none !important; }
}



/* Tablet (Portrait) - design for a width of 768px
%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
*/

/* 768px <= width < 960px */
@media only screen and (min-width: 768px) and (max-width: 959px) {

    /* Default Magento classes
    -------------------------------------------------------------- */
    .nobr { white-space:normal !important; }

    /* Cart
    -------------------------------------------------------------- */
    .cart-table .product-name { font-size:1em; font-family: inherit; }
    .cart-table .product-image { max-width:80px; }

    /* Product page
    -------------------------------------------------------------- */
    /* Related - simple */
    .block-related-thumbnails .product-details {
        margin-left:0;
        margin-bottom:10px;
        clear:left;
    }

    /* Cloud zoom
    -------------------------------------------------------------- */
    /* Shrink the slider if to many thumbnails */
    .itemslider-thumbnails.count-3,
    .itemslider-thumbnails.count-4,
    .itemslider-thumbnails.count-5,
    .itemslider-thumbnails.count-multi { max-width:158px !important; } /*2*/

    /* Direction navigation */
    .itemslider-thumbnails.count-3 .direction-nav,
    .itemslider-thumbnails.count-4 .direction-nav,
    .itemslider-thumbnails.count-5 .direction-nav,
    .itemslider-thumbnails.count-multi .direction-nav { display:block; }

    /* Thumbnails */
    .img-box .thumbnails.show-all.auto-adjust-width .item { max-width: 32%; }

    /* Slideshow caption
    -------------------------------------------------------------- */
    .caption .heading { font-size:2.0833em; }
    .caption p { font-size:1.1666em; }
    .caption button.button span span {
        height: 35px;
        line-height: 35px;
        padding: 0 15px;
    }

}


/* All desktop - design for a width of 768px and wider
%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
*/

/* 768px <= width */
@media only screen and (min-width: 768px) {

    /* Mobile accordion
    -------------------------------------------------------------- */
    .mobile-collapsible .block-title { border-bottom:none; }			/* Hide line when not on mobile */
    .mobile-collapsible .opener { display:none !important; }			/* Always hide when not on mobile */
    .mobile-collapsible .block-content { display:block !important; }	/* Always show when not on mobile */
    /* See also: always make the container "relative" when ON mobile */
}


/* All mobile - design for a width of less than 768px
%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
*/

/* width < 768px */
@media only screen and (max-width: 767px) {

    /* Grid and basic layout
    -------------------------------------------------------------- */

    /* Margin for basic Magento columns */
    .col-main,
    .col-left,
    .col-right
    {
        /* margin-bottom:30px; */
    }

    /* Product view:
       margin for all grid units */
    .product-view .grid12-1,
    .product-view .grid12-2,
    .product-view .grid12-3,
    .product-view .grid12-4,
    .product-view .grid12-5,
    .product-view .grid12-6,
    .product-view .grid12-7,
    .product-view .grid12-8,
    .product-view .grid12-9,
    .product-view .grid12-10,
    .product-view .grid12-11,

        /* deprecated */
    .product-view .grid4-1,
    .product-view .grid4-2,
    .product-view .grid4-3,
    .product-view .grid2-1
        /* end:deprecated */
    {
        margin-bottom:30px;
    }

    /* Remove additional margin for main sidebars */
    .col-left > * { margin-right: 0; }
    .col-right > * { margin-left: 0; }

    /* Magento utility classes
    -------------------------------------------------------------- */
    .nobr { white-space:normal !important; }

    /* Forms
    -------------------------------------------------------------- */
    .form-list .field,
    .form-list input.input-text,
    .form-list textarea,
    .form-list select,
    .form-list li.wide .input-box,
    .form-list li.wide input.input-text,
    .form-list li.wide textarea,
    .form-list li.wide select { max-width:100%; }

    /* Tables
    -------------------------------------------------------------- */
    .data-table thead .nobr { white-space:normal !important; }
    .data-table th,
    .data-table td { padding:5px; }

    /* Data tables formatting
    -------------------------------------------------------------- */

    /* Basic formatting */
    .cart .data-table thead,
    .my-wishlist .data-table thead,
    .my-account .data-table thead,
    .order-review .data-table thead { display:none; }

    .cart .data-table tbody,
    .my-wishlist .data-table tbody,
    .my-account .data-table tbody,
    .order-review .data-table tbody { background:transparent !important; }

    .cart .data-table tr,
    .cart .data-table td,
    .my-wishlist .data-table tr,
    .my-wishlist .data-table td,
    .my-account .data-table tr,
    .my-account .data-table td,
    .order-review .data-table tr,
    .order-review .data-table td { border:none !important; background:transparent !important; display:block; width:100%; text-align:left !important; }

    .cart .data-table tr,
    .my-wishlist .data-table tr,
    .my-account .data-table tr,
    .order-review .data-table tr { border-bottom:1px solid var(--cb-primary-light) !important; margin-bottom:20px; }

    /* Highlight first cell in table footer */
    .my-account .data-table tfoot tr td:first-child,
    .order-review .data-table tfoot tr td:first-child { font-weight:bold; }

    /* Show cell label */
    .data-table .cell-label {
        display:inline-block;
        width:30%;
        min-width:100px;
        max-width:140px;
        margin-right:10px;
        font-weight:bold;
    }
    .data-table .sub-total-item td{
        display:inline-block;
        width:30%;
        min-width:100px;
        max-width:140px;
        margin-right:10px;
        font-weight:bold;
    }
    .data-table .tax-charge td{
        display:inline-block;
        width:30%;
        min-width:100px;
        max-width:140px;
        margin-right:10px;
        font-weight:bold;
    }
    .data-table .total-charge td{
        display:inline-block;
        width:30%;
        min-width:100px;
        max-width:140px;
        margin-right:10px;
        font-weight:bold;}

    /* Header
    -------------------------------------------------------------- */

    /* Global notices */
    .global-site-notice .notice-inner { background:none; padding:10px 0; text-align:center; }
    .global-site-notice .notice-inner p { width:100%; padding:0 0 10px; }
    .global-site-notice .notice-inner .actions { padding-bottom:5px; }

    /* Quick search
    -------------------------------------------------------------- */

    /* Search: mobile (search bar layout for narrow screens)
    -------------------------------------------------------------- */
    .search-wrapper-mobile {
        display: block; /* To override possible inline styles */
        clear: both;
        width: 100%;
        margin: 10px 0;
        /*float: none;*/
    }
    .search-wrapper-mobile .form-search {
        width: 100%;
        max-width: 440px;
        margin-left: auto;
        margin-right: auto;
    }
    .search-wrapper-mobile .form-search .input-text {
        width: 100%;
        min-width: 100%; /* Cancel minimum width */
    }

    /* Search: header inline
    -------------------------------------------------------------- */
    /* Note: added .header class to override other selectors */
    .header .search-wrapper-inline {
        display: block;
        clear: both;
        width: 100%;
        margin: 10px auto;
    }
    /* If wrapper is an .item, then remove side margins */
    .header .item.search-wrapper-inline { margin-left:auto; margin-right:auto; }

    .search-wrapper-inline #search_mini_form { display: block; }
    .search-wrapper-inline .form-search .input-text { width:100%; }

    /* Other
    -------------------------------------------------------------- */
    /* User menu (Top Links) */
    .user-menu { width:100%; margin-left:0; margin-right:0; }

    /* Top links */

    /* Mini cart */
    .after-mini-cart { clear:none; } /* Deprecated */

    /* Line breaker - disable on narrow screens */
    .user-menu .line-break-after { clear:none; }

    /* Footer
    -------------------------------------------------------------- */

    /* Footer elements - align center */

    .footer .mobile-inline-container { text-align:center; }
    .footer .mobile-inline-container > * { float:none; display:inline-block; margin-left:5px; margin-right:5px; }

    .footer-primary-top-spacing,
    .footer-primary-bottom-spacing,
    .footer-top .links,
    .footer-bottom { text-align:center; }

    .footer-primary-top-spacing .item,
    .footer-primary-bottom-spacing .item,
    #subscribe-form,
    .footer-bottom .item,
    .footer-top .links li { float:none; display:inline-block; }
    .footer-top .item { float:none; }

    /* Simplify links */
    .footer .links-wrapper-separators .links li a,
    .footer .links-wrapper-separators-left .links li a { background-image:none; }

    /* Footer elements */
    .footer-primary-bottom-spacing .item { width:100%; margin:0; }
    .footer-bottom .item { margin-left:5px; margin-right:5px; }

    /* Copyright - remove space */
    .footer-copyright { margin-left:0;  }

    /* Primary and secondary section: additional inner padding */
    .footer-primary > .grid-full > .section,
    .footer-secondary > .grid-full > .section { padding:15px 10px; }

    /* Layered navigation
    -------------------------------------------------------------- */
    .block-layered-nav dd li,
    .block-layered-nav .currently ol li { line-height:2.3333em; background-position:3px 10px; }
    .block-layered-nav dd li:hover,
    .block-layered-nav .currently ol li:hover { background-position:6px 10px; }

    /* Toolbar
    -------------------------------------------------------------- */

    /* All elements - align center and increase vertical spacing */
    .pager,
    .sorter { text-align:center; }
    .pager .amount,
    .pager .limiter,
    .pager .pages,
    .sorter .amount,
    .sorter .limiter,
    .sorter .view-mode,
    .sorter .sort-by {}

    .toolbar .sort-by,
    .toolbar .sorter .amount { border-right:none; }

    .sorter .per-page,
    .pager .per-page { display:none; }

    /* Category view
    -------------------------------------------------------------- */

    .note-msg.empty-catalog {
        background-position:center 10px !important;
        color:inherit;
        padding:150px 10px 10px !important;
        text-align:center;
    }

    /* Page heading with RSS link */
    .page-title { position:relative; }
    .page-title .link-rss { float:none; display:block; position:static; margin:10px 0 0; }

    /* Products list
    -------------------------------------------------------------- */
    .products-list .product-shop-inner { padding-right:0; border-right:none; }

    /* Products grid: override size of elements (values like size-s)
    -------------------------------------------------------------- */
    /* Product name */
    .products-grid .product-name {
        font-size:1.1666em; /*14px*/
        line-height:1.2307em; /*16px*/
    }
    /* Buttons */
    .products-grid .item button.btn-cart span span {
        padding:7px 15px;
        height:auto;
        line-height:2em;
    }

    /* Product page
    -------------------------------------------------------------- */

    /* Wrapper for collaterals - remove top margin (grid units already have bottom margin) */
    .product-view .box-additional { margin-top:0; }

    /* Related: remove bottom margin */
    .block-related-slider { margin-bottom:0; }

    /* Sidebar */
    .product-view .custom-sidebar-right .inner { padding-left:0; border-left:none; }
    .product-view .custom-sidebar-left .inner { padding-right:0; border-right:none; }

    /* Add-to links */
    .product-view .add-to-links { text-align: center; }
    .product-view .add-to-links li { float: none; display: inline-block; }

    /* Cart
    -------------------------------------------------------------- */

    /* Buttons below table: align center */
    .cart-table tfoot tr.first td { text-align:center !important; padding:0; margin-bottom:20px; }
    .cart-table tfoot button { display:inline-block; float:none !important; margin:0 0 3px; padding:0; }

    /* One Page Checkout
    -------------------------------------------------------------- */

    /* Step title - link "edit" */
    .opc .allow .step-title a { display:none; }

    /* Form */
    /*.opc form fieldset { display:table-column-group; }*/

    /* Account
    -------------------------------------------------------------- */
    .my-wishlist .product-image { max-width:none;  }

    /* Items slider
    -------------------------------------------------------------- */
    .control-nav.control-paging { display:none; } /* Deprecated */

    /* If only one item exists */
    .itemslider.single-item .slides > li { margin: 0 auto; } /* Deprecated */

    /* Slideshow
    -------------------------------------------------------------- */

    /* Slideshow navigation: move buttons to the top right corner */
    .the-slideshow-wrapper .slider-arrows2 .owl-controls .owl-buttons .owl-prev { top:10px; right:60px; left:auto; }
    .the-slideshow-wrapper .slider-arrows2 .owl-controls .owl-buttons .owl-next { top:10px; right:10px; left:auto; }

    /* Deprecated */
    .the-slideshow .direction-nav .prev { top:4%; right:63px; left:auto; }
    .the-slideshow .direction-nav .next { top:4%; right:2%; }

    /* Pagination */
    .the-slideshow-wrapper .owl-pagination { display: none; }

    /* Slideshow caption */
    /* Shrink font, hide all elements except heading */
    .caption > * { display:none !important; }
    .caption .heading { display:inline-block !important; font-size:2.0833em; margin-bottom:1px; }
    .caption p { font-size:1.1666em; margin-bottom:1px; }
    .caption button.button span span {
        height: 30px;
        line-height: 30px;
        padding: 0 10px;
    }

    /* Cloud Zoom
    -------------------------------------------------------------- */
    /* Hide zoom */
    .cloud-zoom-big,
    .cloud-zoom-lens { display:none !important; }
    .cloud-zoom-lens { cursor:default !important; }

    /* fix transparent issue */
    #cloud-zoom-big, .cloud-zoom-big { background-color: #fff; }

    /* Thumbnails */
    .img-box .thumbnails.show-all.auto-adjust-width .item { max-width: 16%; }

    /* Lightbox
    -------------------------------------------------------------- */
    #cboxTitle,
    #cboxCurrent { display:none !important; }

    /* Accordion
    -------------------------------------------------------------- */
    .accordion-style1 li a {
        padding:15px 10px;
        font-size: 1.1666em; /*14px*/
        line-height: 1.2857em; /*18px*/
    }
    /* Space for openner if item has children. It has to be a few pixels wider than openner */
    .accordion-style1 li.parent > a { padding-right:53px; }
    .accordion-style1 .opener { width:48px; height:48px; }

    /* Mobile accordion
    -------------------------------------------------------------- */
    .mobile-collapsible .block-content { display:none; } /* Hide on mobile */
    .mobile-collapsible { position:relative; } /* Always make "relative" when not on mobile (to avoid flickering on CSS transitions) */

    /* Menu
    -------------------------------------------------------------- */


}
/* end: width < 768px */


/* Mobile (Landscape) - design for a width of 480px
%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
*/

/* 480px <= width < 768px */
@media only screen and (min-width: 480px) and (max-width: 767px) {

    /* Category view
    -------------------------------------------------------------- */
    .products-list .product-shop,
    .products-list .right-column,
    .products-list .product-image-wrapper {}

    /* Cloud zoom
    -------------------------------------------------------------- */
    /* Broaden the slider if at least 4 thumbnails */
    .itemslider-thumbnails.count-4,
    .itemslider-thumbnails.count-5,
    .itemslider-thumbnails.count-multi { max-width:316px; } /*4*/

    /* Direction navigation */
    .itemslider-thumbnails.count-4 .direction-nav { display:none; }

    /* Shrink and align center the main image */
    .img-box .product-image { width:60.4651%; /*364px*/ }

}
/* end: 480px <= width < 768px */


/* Design for a width of 640px
%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
*/

/* 640px <= width < 768px */
@media only screen and (min-width: 640px) and (max-width: 767px) {

    /* Item grid
    -------------------------------------------------------------- */
    .category-products-listing.products-list .item,
    .itemgrid.itemgrid-adaptive .item { width:33.3333%; clear:none !important; }
    .category-products-listing.products-list .item:nth-child(3n+1),
    .itemgrid.itemgrid-adaptive .item:nth-child(3n+1) { clear:left !important; }
}
/* end: 640px <= width < 768px */


/* Design for a width of less than 640px
%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
*/

/* width < 640px */
@media only screen and (max-width: 639px) {

    h1 { font-size:2em;			line-height:1.25em;		/*24px, 30px*/ }
    h2 { font-size:1.6666em;	line-height:1.2em;		/*20px, 24px*/ }
    h3 { font-size:1.3333em;	line-height:1.25em;		/*16px, 20px*/ }
    h4 { font-size:1.1666em;	line-height:1.1428em;	/*14px, 16px*/ }
    h5 { font-size:1em;			line-height:1em;		/*12px, 12px*/ }
    h6 { font-size:1em;			line-height:1em;		/*12px, 12px*/ }



    /* Cloud zoom
    -------------------------------------------------------------- */
    /* Thumbnails */
    .img-box .thumbnails.show-all.auto-adjust-width .item { max-width: 19%; }
}
/* end: width < 640px */


/* Design for a width of 480px
%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
*/

/* 480 <= width < 640px */
@media only screen and (min-width: 480px) and (max-width: 639px) {

    /* Item grid
    -------------------------------------------------------------- */

    .category-products-listing.products-list .item,
    .itemgrid.itemgrid-adaptive .item { width:50%; clear:none !important; }
    .category-products-listing.products-list .item:nth-child(2n+1),
    .itemgrid.itemgrid-adaptive .item:nth-child(2n+1) { clear:left !important; }
}
/* end: 480 <= width < 640px */


/* Design for a width of less than 480px (small mobile)
%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
*/

/* width < 480px */
@media only screen and (max-width: 479px) {



    /* Products grid
    -------------------------------------------------------------- */
    .products-grid.category-products-grid.hover-effect .item .add-to-links, /* To override "display-onhover" */
    .products-grid .item .add-to-links { display: none !important; }

    /* Buttons
    -------------------------------------------------------------- */
    /* Button checkout + button "Add to cart" on product page */
    button.btn-checkout span span,
    .product-view .btn-cart span span { padding:5px 10px; }

    /* Grid elements
    -------------------------------------------------------------- */
    .col2-set .col-1,
    .col2-set .col-2 { width:100%; }

    /* Header
    -------------------------------------------------------------- */

    /* Mini cart */
    #mini-cart .dropdown-toggle { -moz-box-shadow:none !important; -webkit-box-shadow:none !important; box-shadow:none !important; }

    #mini-cart .dropdown-toggle.cover > div { background-color: transparent; color: inherit; }
    #mini-cart .dropdown-toggle span.icon { transition:none; }
    #mini-cart .dropdown-toggle .name { display:none; }

    #mini-cart .dropdown-toggle .caret { display:none !important; }
    #mini-cart .dropdown-menu { display:none !important; }
    #mini-cart.hide-empty-cart { display:none !important; }

    /* Quick compare dropdown */
    #quick-compare .dropdown-toggle { -moz-box-shadow:none !important; -webkit-box-shadow:none !important; box-shadow:none !important; }

    #quick-compare .dropdown-toggle.cover > div { background-color: transparent; color: inherit; }
    #quick-compare .dropdown-toggle span.icon { transition:none; }
    #quick-compare .dropdown-toggle .name { display:none; }

    #quick-compare .dropdown-toggle .caret { display:none !important; }
    #quick-compare .dropdown-menu { display:none !important; }
    #quick-compare.is-empty { display:none !important; }

    /* Header elements - align center
    -------------------------------------------------------------- */

    .header .mobile-inline-container { text-align:center; }
    .header .mobile-inline-container > * { float:none; display:inline-block; margin-left:5px; margin-right:5px; }

    /* Links */
    .header .links { text-align:center; }
    .header .links li { float:none; display:inline-block; }

    /* Header top */
    .header-top { text-align:center; }
    .header-top .item { float:none; display:inline-block; margin-left:5px; margin-right:5px; }

    /* Inside user menu */
    .user-menu { text-align:center; }
    .user-menu > *,
    .user-menu .links, /*Some blocks of links may be deeper than one level*/
    .dropdown { display:inline-block !important; }

    /* previous:
    .user-menu { text-align:center; }
    .user-menu .top-links { float:none; }
    #mini-cart,
    .user-menu .links,
    .user-menu .links li { float:none; display:inline-block; }
    */

    /* Simplify horizontal links */
    .header .links-wrapper-separators .links li a,
    .header .links-wrapper-separators-left .links li a { background-image:none; }

    /* Products list
    -------------------------------------------------------------- */
    .products-list .item { margin-bottom:20px; }
    .products-list .item:first-child { margin-top:1px; /* Move item down to uncover the border of the toolbar */ }
    .products-list .product-image { margin-bottom:20px; }

    /* Products list: disable hover effect
    -------------------------------------------------------------- */
    /* Cancel "hover effect" styles: apply the same styles which item has without "hover effect" */
    .products-list.hover-effect .item { border-top: none; }
    .products-list.hover-effect .item:hover {
        margin-left:0;
        margin-right:0;
        padding-left:0;
        padding-right:0;
        box-shadow: none !important;
    }

    /* Products grid: override size of elements (values like size-xs)
    -------------------------------------------------------------- */
    /* Product labels */
    .products-grid .sticker { width:40px; height:40px; font-size:0.9166em; }
    .products-grid .item:hover .sticker { width:45px; height:45px; }

    /* Product page
    -------------------------------------------------------------- */

    /* Reviews */
    .product-view .box-reviews .form-add .data-table th,
    .product-view .box-reviews .form-add .data-table td { padding:6px 1px; }
    .product-view .box-reviews .form-add .form-list input.input-text,
    .product-view .box-reviews .form-add .form-list textarea { width:90%; }

    /* Add-to links */
    .product-view .add-to-links { text-align: inherit; }

    /* Toolbar
    -------------------------------------------------------------- */

    .toolbar .limiter { display:none; }
    .sorter .view-mode { display:none; }
    .sorter .sort-by { background:none; margin-right:0; margin-left:0; padding-right:0; padding-left:0; } /* Remove right divider */

    /* Pages */
    .pager .pages li { display:none; }
    .pager .pages li.previous,
    .pager .pages li.current,
    .pager .pages li.next { display:block; }

    /* Account
    -------------------------------------------------------------- */

    /* Account info + Order info */
    /*
    .box-account.box-info .col-1,
    .order-info-box .col-1 { border-right:none; }
    .box-account.box-info .col-1 .box,
    .order-info-box .col-1 .box { padding-right:0; }
    */

    .my-account .col2-set .col-1 { border-right:none; margin-bottom:20px; }
    .my-account .col2-set .col-1 .box { padding-right:0; }

    /* Order details */
    .order-items .sub-title .separator { display:none; }

    /* Checkout
    -------------------------------------------------------------- */
    .opc .step-title h2 { font-size:1em; line-height:2.8333em; }

    /* Advanced search results
    -------------------------------------------------------------- */
    .advanced-search-summary ul { width: 100%; margin-right: 0; }

    /* Footer
    -------------------------------------------------------------- */
    #subscribe-form label {
        display: inline-block;
        float: none;
        line-height: 1em;
        margin: 15px 0 2px;
    }
    #subscribe-form .input-box {
        display: inline-block;
        float: none;
        margin: 5px 0;
        position: relative;
        width: 100%;
    }
    #subscribe-form .input-text { width:100%; max-width:100%; }
    #subscribe-form .button {
        display: inline-block;
        float: none;
    }
    /*TOOD: check on mobiles*/
    #subscribe-form .input-box .validation-advice { width: 100%; left: 0; }

    /* Slideshow
    -------------------------------------------------------------- */
    .the-slideshow .control-nav { display:none; } /* Deprecated */

    /* Slideshow caption */
    /* Shrink font. Hide all elements, even with class "permanent" (except heading with "permanent") */
    .caption > *,
    .caption .heading,
    .caption .permanent { display:none !important; }
    .caption .heading.permanent { display:inline-block !important; }

    .caption .heading { font-size:1.3333em; }
    .caption p { font-size:1em; }

    /* Itemslider
    -------------------------------------------------------------- */
    /* Pagination */
    .itemslider .owl-pagination { display: none; }

    /* Cloud zoom
    -------------------------------------------------------------- */
    #zoom-btn { display:none; }

    /* Thumbnails */
    .img-box .thumbnails.show-all.auto-adjust-width .item { max-width: 24%; }

}
/* end: width < 480px */


/* Design for a width of 320px (mobile - portrait)
%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
*/

/* 320px <= width < 480px */
@media only screen and (min-width: 320px) and (max-width: 479px) {

    /* Item grid
    -------------------------------------------------------------- */

    .category-products-listing.products-list .item,
    .itemgrid.itemgrid-adaptive .item { width:50%; clear:none !important; }
    .category-products-listing.products-list .item:nth-child(2n+1),
    .itemgrid.itemgrid-adaptive .item:nth-child(2n+1) { clear:left !important; }

    /* Cloud zoom
    -------------------------------------------------------------- */
    /* Shrink the slider if to many thumbnails */
    .itemslider-thumbnails.count-3,
    .itemslider-thumbnails.count-4,
    .itemslider-thumbnails.count-5,
    .itemslider-thumbnails.count-multi { max-width:237px; } /*3*/		/*{ max-width:158px; }*/ /*2*/

    /* Direction navigation */
    .itemslider-thumbnails.count-3 .direction-nav,
    .itemslider-thumbnails.count-4 .direction-nav,
    .itemslider-thumbnails.count-5 .direction-nav,
    .itemslider-thumbnails.count-multi .direction-nav { display:block; }

}
/* end: 320px <= width < 480px */


/* Design for a width of less than 320px
%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
*/

/* width < 320px */
@media only screen and (max-width: 319px) {


    /* Disable hover effect
    -------------------------------------------------------------- */
    /* Cancel "hover effect" styles: apply the same styles which item has without "hover effect" */
    .category-products-grid.hover-effect .item { border-top: none; }
    .category-products-grid.hover-effect .item:hover {
        margin-left:0;
        margin-right:0;
        padding-left:1%;
        padding-right:1%;
        box-shadow: none !important;
    }

    /* Show elements normally displayed only on hover */
    .category-products-grid.hover-effect .item .display-onhover { display:block !important; }

    /* Show full name even if enabled: display name in single line */
    .products-grid.single-line-name .item .product-name { overflow: visible; white-space: normal; }

    /* Spaces between items */
    .category-products-grid.hover-effect .item { margin-bottom: 20px; }


    /* Product page
    -------------------------------------------------------------- */
    /* Related - thumbnails */
    .block-related-thumbnails .product-details {
        margin-left:0;
        margin-bottom:10px;
        clear:left;
    }
    .block-related-thumbnails .product-details .price-box { display: inline-block; margin-right:5px; }
    .block-related-thumbnails .product-details .related-add-to-wishlist { display: inline-block; }

    /* Item grid
    -------------------------------------------------------------- */
    /* Always show 1 column */
    /* Important: added ".itemgrid" class to override other styles */
    .category-products-listing.products-list .item,
    .itemgrid.itemgrid-adaptive .item { width:100%; clear:none !important; }

    /* Cloud zoom
    -------------------------------------------------------------- */
    /* Thumbnails */
    .img-box .thumbnails.show-all.auto-adjust-width .item { max-width: 32%; }

    /* Slideshow
    -------------------------------------------------------------- */
    .the-slideshow-wrapper .owl-buttons { display: none; }

    .the-slideshow .direction-nav { display:none; } /* Deprecated */

}
/* end: width < 320px */


/* Design for a width of 240px (small mobile)
%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
*/

/* 240px <= width < 320px */
@media only screen and (min-width: 240px) and (max-width: 319px) {

    /* Cloud zoom
    -------------------------------------------------------------- */
    /* Shrink the slider if to many thumbnails */
    .itemslider-thumbnails.count-3,
    .itemslider-thumbnails.count-4,
    .itemslider-thumbnails.count-5,
    .itemslider-thumbnails.count-multi { max-width:158px; } /*2*/

}
/* end: 240px <= width < 320px */


/* Design for a width of lass than 240px
%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
*/

/* width < 240px */
@media only screen and (max-width: 239px) {

    /* Cloud zoom
    -------------------------------------------------------------- */
    /* Shrink the slider if to many thumbnails */
    .itemslider-thumbnails.count-2,
    .itemslider-thumbnails.count-3,
    .itemslider-thumbnails.count-4,
    .itemslider-thumbnails.count-5,
    .itemslider-thumbnails.count-multi { max-width:79px !important; } /*1*/

    /* Direction navigation */
    .itemslider-thumbnails.count-2 .direction-nav,
    .itemslider-thumbnails.count-3 .direction-nav,
    .itemslider-thumbnails.count-4 .direction-nav,
    .itemslider-thumbnails.count-5 .direction-nav,
    .itemslider-thumbnails.count-multi .direction-nav { display:block; }

}
/* end: width < 240px */

@media only screen and (max-width: 767px) {
    .cart .data-table td, .cart .data-table tr, .my-account .data-table td, .my-account .data-table tr, .my-wishlist .data-table td, .my-wishlist .data-table tr, .order-review .data-table td, .order-review .data-table tr {
        text-align: center !important;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }
}