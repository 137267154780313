// Root variables
:root {
    --duration-short: 100ms;
    --duration-default: 350ms;
    --duration-long: 500ms;
    --zoom-in-ratio: 1;
  }
  
  // Base animation container
  .cb-animate {
    overflow: hidden;
    position: relative;
  }
  
  // Ambient rotation animation
  .cb-animate .cb-animate--ambient {
    animation: animateAmbient 20s linear infinite;
  }
  
  @keyframes animateAmbient {
    0% {
      transform: rotate(0deg) translateX(2em) rotate(0deg) scale(1.2);
    }
    100% {
      transform: rotate(360deg) translateX(2em) rotate(-360deg) scale(1.2);
    }
  }
  
  // Zoom effect on scroll
  .cb-animate .cb-animate--scroll-zoom-in {
    transition: scale 0.25s linear;
    scale: var(--zoom-in-ratio);
  }
  
  // Vertical floating animation
  .cb-animate .cb-animate--shake {
    animation: vertical-floating 2s ease-in-out infinite;
    --translate-x: 0;
  }
  
  @keyframes vertical-floating {
    0%,
    100% {
      transform: translateX(var(--translate-x)) translateY(0);
    }
    50% {
      transform: translateX(var(--translate-x)) translateY(6px);
    }
  }
  
  // Slide animations
  .cb-animate--slide-down,
  .cb-animate--slide-up {
    position: absolute;
    width: 100%;
    padding: 0;
    margin: 0;
    z-index: 10;
    opacity: 0;
    transition: 
      transform var(--duration-long) ease,
      opacity var(--duration-default) ease;
    left: 0;
  }
  
  .cb-animate--slide-down {
    top: 0;
    transform: translate3d(0, -15px, 0);
  }
  
  .cb-animate--slide-up {
    bottom: 0;
    transform: translate3d(0, 15px, 0);
  }
  
  div:hover > .cb-animate--slide-down,
  div:hover > .cb-animate--slide-up {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    transform: translateZ(0);
    background: rgba(255, 255, 255, 0.9);
  }
  
  @keyframes slide-up {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
  
  // Horizontal shaking animation
  .cb-animate--horizontal-shaking {
    animation: horizontal-shaking 6s ease infinite;
    animation-delay: 5s;
    transform-origin: 50% 50%;
  }
  
  @keyframes horizontal-shaking {
    0%,
    18%,
    100% {
      transform: translate3d(0, 0, 0);
    }
    2%,
    6%,
    10%,
    14% {
      transform: translate3d(-5px, 0, 0);
    }
    4%,
    8%,
    12%,
    16% {
      transform: translate3d(5px, 0, 0);
    }
  }

  // Image zoom on hover
  .cb-animate--image-zoom img {
    will-change: transform;
    transition: transform .64s ease;
  }
  @media (min-width: 1025px) {
    .cb-animate--image-zoom:hover img {
      transform: scale(1.06);
    }
  }