.xm-grid-product .grid-group {
    margin: 1rem 0;
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
}

.product-shop h1 {
    color: var(--cb-color);
    font-size: 2.5em;
    text-transform: initial;
}
.product-shop ul {
    list-style: disc inside;
}

.product-shop .product-options dd {
    padding-left: 0;
}

.product-shop .qty-wrapper label {
    display: none;
}

.product-shop .item-code-label, .gen-tabs .tabs a
{
    text-transform: uppercase;
}
.product-shop .short-description {
    font-size: 16px;
    line-height: 1.75;
    padding: 1em 0;
    font-weight: 100;
}

.product-shop ul {
    list-style: disc inside;
    text-align: left;
}
.product-shop ul li {
    font-size: 1em;
    line-height: 1.75;
    font-weight: 100;
}

.product-shop .price-box .regular-price .price {
    line-height: initial;
}

.product-shop .qty-wrapper input {
    min-width: auto;
    font-size: 1.1666em;
    width: 100% !important;
}

.product-shop .add-to-cart {
    width: 100%;
}

.product-shop button.add-cart-button {
    width: 100%;
}

/* .product-shop button.add-cart-button span {
    width: 100%;
    box-sizing: border-box;
} */

.product-shop .btn-cart span span {
    font-size: 1.1666em;
}

/* .product-shop button.add-cart-button span {
    background-color:var(--cb-primary-dark);
}

.product-shop button.add-cart-button span:hover,
.product-shop button.add-cart-button:hover span {
    background-color:var(--cb-secondary-main) !important;
} */

/*align tier price to left*/
.product-shop ul {
    list-style:disc inside;
    text-align:left;
}

.product-shop .qty-wrapper {
    margin:0;
}


.module-product-image .more-images .slider-thumb .slick-prev, 
.module-product-image .more-images .slider-thumb .slick-next {
    background-color: var(--cb-accent-3);
    opacity: 1;
    color: var(--cb-accent-color);
}

.module-product-image .more-images .slider-thumb .slick-prev:hover, 
.module-product-image .more-images .slider-thumb .slick-next:hover {
    background-color: var(--cb-accent-4);
    opacity: 1;
    color: var(--cb-accent-color);
}