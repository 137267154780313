/* user ACcount */

.block-account .block-content ul {
    position:relative;
    display:flex;
    justify-content:center;
    list-style:none;
}

.grid-col2-sidebar.sidebar-wrapper .block-account .block-content ul {
    flex-direction: column;
}

.xm-cms-user-account .main {
    padding:0;
}

.ReactTable {
    font-size:16px
}
.ReactTable .DayPickerInput input {
    width:100px !important;
}
/* Graeme's edits for Paul */
.ReactTable .rt-thead .rt-tr {
    text-align:left!important;
}
.braintree-field-container {
    width:50%;
    min-width:215px;
}
.rego-field-general.buttons-set.btn-step.invoice {
    float:left;
    margin:0;
}

.my-account .pager .amount {
    padding: 0;
    margin: 0;
}
.my-account .pager,
.my-account .pager .pages {
    display: flex;
    align-items: center;
    gap: 10px;
}

.my-account .pager .pages ol {
    padding: 0;
}

.page-type-user .pager {
    border-top: none;
    border-bottom: none;
}
.xm-cms-user-account .block-account .block-title{
    display:none;
}

.cc_form_braintree .hosted-field {
    height: var(--cb-global-form-input-height) !important; 
    border-radius: 0 !important;
    border: 1px solid var(--cb-primary-light) !important;
    line-height: var(--cb-global-icon-line-height) !important;
    background: #fff !important;
    padding: 0 7px !important;
}

/* React Table */
.ReactTable span i.fa{
    font-style: normal !important;
}
.ReactTable .-pagination .-btn {
    border-radius: 0 !important;
}
.ReactTable .-pagination .-btn:not([disabled]):hover {
    background: var(--cb-secondary-main) !important;
}

.ReactTable .rt-thead.-filters input, .ReactTable .rt-thead.-filters select {
    height: var(--cb-global-form-input-height);
    line-height: var(--cb-global-form-input-line-height);
    padding: 0 10px;
    border-radius: 0;

}
/* eof React Table */
