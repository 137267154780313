/* Cart */


.continue-shopping button {
    float:right;
}

.page-type-cart #cart_progress_navi {
    display: none;
}

.xm-grid-cart .cart-table {
    overflow-x: auto;
    overflow-y: hidden;

}

.page-type-cart input.quantity {
    max-width: 55px;
}

.xm-grid-cart .grid-container {
    align-items: inherit;
}

.cart .crosssell h2, .cart .discount h2, .cart .shipping h2, .section-title {
    text-transform: uppercase;
}

.cart-action-buttons {
    display: flex;
    gap: 0.2em;
    /* flex-wrap: wrap;
    gap:3px 3px; */
}

.additional-bg-color, .block-layered-nav .currently, .cart .totals {
    background-color:transparent;
    border:var(--cb-border-width) var(--cb-border-style) var(--cb-border-color);
    overflow: auto;
}

.cart .totals .checkout-types li {
    clear:both;
    margin:10px 0;
    text-align:center;
}
.a-right {
    text-align:left !important;
}
.checkout-types li span.price {
    text-align:right;
}

.checkout-types button.button,
.checkout-types button.button span {
    width: 100%;
    /* color:#fff;
    background-color:var(--cb-primary-dark); */
    font-size: 1.1666em;
}

.grand-total-row {
    border-top:1px solid var(--cb-gray-5);
    border-bottom:1px solid var(--cb-gray-5);

}

/* quickorders */
.checkout-multishipping-shipping .rTableCell {
    box-sizing: border-box;
}
.search-box .search-btn-quickorder {
    top: 15px !important;
    color: #777;
}

input.qty {
    height: var(--cb-global-form-input-height);
    border:1px solid var(--cb-gray-6, var(--cb-primary-light));
}

.product_desc {
    height: 36px !important;
}

@media only screen and (max-width: 767px) {
    .search-box .search-btn-quickorder {
        top: 23px !important;
    }
}

/* eof quickorders */