.module-hot-deals__actionButtons {
   // Add to Cart
   .actions.add-cart-wrapper {
    display: grid;
    // grid-template-columns: 1fr 1fr;
    // grid-template-rows: auto auto;
    // width: 100%;
    gap: 0.5rem;
    justify-content: center;
    .qty-wrapper {
      grid-column: 1 / span 2;
      align-self: center;
      justify-self: center;
    }
  } 
}