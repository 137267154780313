.page-type-cart #opc-review .discount-form .input-box {
    float: left;
    width: 25%;
}
#opc-review .discount-form .input-text {
    height: 45px;
}
.page-type-cart #opc-review .discount-form .discount_button {
    margin: 0 15px;
}
.page-type-cart #checkout-review-table-wrapper input:not(#promo):not([name="terms_conditions"]),
.page-type-cart #checkout-review-table-wrapper textarea {
	width: 70%;
}
.page-type-cart #checkout-review-table-wrapper input:not(#promo):not([name="terms_conditions"]),
.page-type-cart #checkout-review-table-wrapper textarea {
	width: 70%;
}
.cart_confirm a.button{
    background-color: var(--cb-primary-dark);
}
.cart_confirm a.button span span{
    padding: 0 25px;
}

#paymentScreen .payment-confirm-note {
    padding: 15px 5px 0 0;
}

.page-type-cart #checkout-review-table .col-img img{
    max-width: none;
}

/*---REVIEW-------------------------------------------------------------------*/
#opc-review .buttons-set { border:0;padding: 15px 0 15px 30px;}

#opc-review .item-secondry-heading {
    white-space: pre-line;
}
#opc-review #checkout-review-submit .buttons-set {
    padding-right: 0px;
    margin-right: 0px;
}
#opc-review #checkout-review-submit .buttons-set button{
    padding-right: 0px;
    margin-right: 2px;
}
#opc-review .terms_conditions {
    clear: both;
    float: right;
    margin: 15px 0 5px 0;
}
#opc-review .terms_conditions h2{
    border-bottom: 1px solid var(--cb-primary-light);
    font-size: 1.5em;
    font-weight: normal;
    line-height: 1.2222em;
    padding-bottom: 7px;
    padding-top: 7px;
}
#opc-review .product-name a{
    font-size: 12px;
    font-weight: normal;
}
#opc-review .product-name p{
    font-size: 13px;
    font-weight: bold;
}

.place-order .grand-total{
    text-align: right;
}
.place-order .grand-total .inner {
    display: inline;
    margin-right: 20px;
    font-size: 1em;
}
.shipping_show {
    visibility:visible;
}
.shipping_hide {
    visibility:visible;
}
.single-order h2{
    display:none;
}
.single-order address{
    display:none;
}
.single-order .box-title{
    display:none;
}
div.col-wide.single-order{
    display:none;
}
.bigtotal {
    font-weight: bold;
    font-size: 16px;
    padding-right:5px;
}

.cart-item-desc {
    text-transform: uppercase;
}