.quick-view-modal-dialog #add-to-cart-block {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
  > *:not(:first-child) {
    margin-right: 1em;
  }
}

.quick-view-modal-dialog .modal-header .button.button-close {
  position: absolute;
  top: -15px;
  right: -15px;
}

.modal .modal-body {
  overflow: hidden;
}

.modal .modal-footer {
  border: none;
}

.modal .modal-content {
  border-radius: initial;
}