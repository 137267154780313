

/*@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;*/
/*400;*/
/*700&display=swap');*/

/*background-color:#003087;*/
/*}*/
.header-multi-column {
margin:auto;
display:block;
float:none;
}
.nav-item.parent {
display:flex !important;
flex-direction:column;
align-content:stretch !important;
}

.page-item {
    background:no-repeat !important;
    padding:0px 0px 10px 0px !important;
}

.homepage-carousel-caption {
    margin:0;
    position:absolute;
    bottom:10%;
    left:45%;
}
.shop-now {
    text-transform:uppercase;
    color:#fff;
    border:4px solid var(--cb-gray-6, #fff);
    padding:10px 20px;
    font-size:15px;
    font-weight:800;
}
.customer-spotlight {
    background-color:#f3f3f3;
}
.spotlight-text {
    background-color:#fff;
}s

/*end replacement of user link icon*/
.search-user-links .header-top-container {
    background-color:#fff !important;
    border:none;
    padding-top:4%;
}
/*Mini Cart*/
/*#mini-cart {*/
/*    margin-top:0;*/
/*}*/
/*#mini-cart .actions .button.btn-inline span, #mini-cart .actions .button.btn-inline:hover span {*/
/*    margin:0 5px;*/
/*    padding:0 !important;*/
/*}*/
/*#mini-cart .actions button {*/
/*    float:left;*/
/*    margin-right:1px;*/
/*    margin:2px;*/
/*    background-color:transparent;*/
/*    color:#1b262c;*/
/*    border:1px solid #1b262c;*/
/*}*/

/*Mega menu*/
.mega-menu p {
    margin:0 0 0.5em !important;
}
.mega-menu {
    width:10%;
}
/*You may also like hot deal*/
.you-may-also-like {
    text-align:center;
    text-transform:uppercase;
    text-decoration:underline;
    text-decoration-color:#65318e;
}

/*Contact Us page submit button*/
.contact-from button.button.left span span:hover {
    padding:0 25px !important;
}
.contact-form button.button span span {
    width:100%;
    border:0.5px solid var(--cb-gray-6, #777) !important;
    padding:0 25px !important;
}

.search-quick-view .add-to-cart {
    width:100%;
}
.ReactTable {
    font-size:16px
}
/* width > 960px */
@media only screen and (min-width:960px) {
    /* Hide */
    .hide-above-960 {
        display:none !important;
    }
}
/* --------- QUICK ORDER ---------------- */
.inline-search-results-quickorder .highlight {
    background-color:#78c9cd;
}



/* Product Details*/


.add-cart-fixed {
    display:none !important;
}
.rating-links {
    display:block !important;
}

/*Product spec table*/
/*hidden so table resizes.*/
/*#product-alones .productTabContent table {
     width:1000px !important;
}
*/
#product-alones .productTabContent table {
    width:100% !important;
}
/*related item styling*/
/*.products-grid.size-s .item button.btn-cart span span {*/
/*    padding:0 !important;*/
/*    height:auto;*/
/*    line-height:2em;*/
/*}*/


.section-title.padding-right {
    padding-right:0 !important;
    font-size:1.6em;
    letter-spacing:1.8px;
}

/*Product tab title styling*/
.product-tab-title span.column-title.section-title {
    font-size:1.6em;
    letter-spacing:1.8px;
    text-align:center;
}
/*collapsible product tabs*/
@media only screen and (min-width:1000px) {
    /* Mobile accordion-------------------------------------------------------------- */
    .mobile-collapsible-product .block-title {
        border-bottom:none;
    }
    /* Hide line when not on mobile */
    .mobile-collapsible-product .opener {
        display:block !important;
    }
    /* Always hide when not on mobile */
    .mobile-collapsible-product .block-content {
        display:none !important;
    }
    /* Always show when not on mobile */
    /* See also:always make the container "relative" when ON mobile */
}
.mobile-collapsible-product .block-content {
    display:block;
}
/* Hide on mobile */
.mobile-collapsible-product {
    position:relative;
}
/* Always make "relative" when not on mobile (to avoid flickering on CSS transitions) */
/* Collapsible %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */
.collapsible {
    position:relative;
}
.collapsible .block-title {
    padding-top:8px;
    padding-right:35px;
    /* Space for opener */
    padding-bottom:8px;
    border-bottom:1px solid var(--cb-gray-6, #f2f2f2);
    border-color:var(--cb-gray-6, rgba(0,0,0, 0.05));
    margin-bottom:0.625em;
    /*10px*/
    font-size:1.3333em;
    /*16px*/
    line-height:1.25em;
    /*20px*/
    font-weight:normal;
}
.collapsible .block-content, .collapsible .block-content > * {
    margin-top:0;
    /* Prevent animation jumping */
}
/* Opener-------------------------------------------------------------- */
.collapsible .opener {
    position:absolute;
    right:10%;
    top:2px;
    display:block;
    cursor:pointer;
    width:30px;
    height:30px;
    background-color:#f2f2f2;
    /*IE*/
    background-color:transparent;
    background-position:center center;
    background-repeat:no-repeat;
    /*border-radius:50%;
    */
}
/* Mobile collapsible-------------------------------------------------------------- */
.mobile-collapsible-product {
    position:static;
}
/* Mobile accordion-------------------------------------------------------------- */
.mobile-collapsible-product .block-content {
    display:block;
}
/* Hide on mobile */
.mobile-collapsible-product {
    position:relative;
}
/* Always make "relative" when not on mobile (to avoid flickering on CSS transitions) */
.collapsible .opener {
    transition:background-color 300ms ease-in-out, background-position 300ms ease-in-out;
    -moz-transition:background-color 300ms ease-in-out, background-position 300ms ease-in-out;
    -webkit-transition:background-color 300ms ease-in-out, background-position 300ms ease-in-out;
    -o-transition:background-color 300ms ease-in-out, background-position 300ms ease-in-out;
}
.collapsible .opener:hover {
    background-color:transparent;
    background-image:url("https://commercebuild-themes.mysagestore.com/static/microscooters/images/opener.png") !important;
}
.collapsible.active .opener:hover {
    background-image:url("https://commercebuild-themes.mysagestore.com/static/microscooters/images/opener-active.png") !important;
}
.mobile-collapsible-product .module-product-details, .mobile-collapsible-product .box-additional.box-tabs {
    display:none;
}
.mobile-collapsible-product.active .module-product-details, .mobile-collapsible-product.active .box-additional.box-tabs {
    display:block;
}
/*Product spec table responsive*/
@media only screen and (max-width:900px) {
    #product-alones .productTabContent table {
        width:1000px !important;
        overflow:scroll !important;
    }
    .productTabContent table caption, td, th {
        vertical-align:top;
        text-align:left !important;
        font-weight:400;
    }
    .products-grid.size-s .product-name {
        height:40px;
    }
}



/*cart summary button*/


/*hot deal module item height*/
.owl-carousel.owl-drag .owl-item {
    height:auto !important;
}
/*border around shipping*/
.cart-estimate {
    border:1px solid var(--cb-gray-6, rgb(119, 119, 119));
    padding:1%;
}
/*adding border to cart estimate changes layout*/
/*.cart-estimate {
     border:1px solid #777;
}
*/


