/* Home */
.home-block .page-content {
    padding: 20px;
    padding-left: 75px;
    border: 1px solid #000;
    margin-bottom: 20px;
    position: relative;
}

.home-block .page-content:hover {
    -webkit-box-shadow: 0 1px 4px rgb(0 105 214 / 25%);
    -moz-box-shadow: 0 1px 4px rgba(0, 105, 214, 0.25);
    box-shadow: 0 1px 4px rgb(0 105 214 / 25%);
    cursor: pointer;
}

.home-block .page-content::before {
    font-family: fontAwesome;
    font-size: 42px;
    position: absolute;
    left: 20px;
    top: 32px;
}

.home-block.shop .page-content::before {
    content: '\f02c';
}

.home-block.prices .page-content::before {
    content: '\f02a';
}

.home-block.favorites .page-content::before {
    content: '\f005';
}

.home-block.bulk-cart .page-content::before {
    content: '\f0e7';
}

.home-block.orders .page-content::before {
    content: '\f0f6';
}

.home-block.shipments .page-content::before {
    content: '\f0d1';
}

.home-block.invoices .page-content::before {
    content: '\f01c';
}

.home-block.payments .page-content::before {
    content: '\f09d';
}

.home-block.account .page-content::before {
    content: '\f044';
}

.home-block.quotes .page-content::before {
    content: '\f0e7';
}

.home-block h2, .home-block h2 a, .home-block h2 a strong, .home-block p, .home-block p a {
    margin: 0 !important;
    white-space: nowrap;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
}

.home-block h2 {
    line-height: 30px;
}

.home-block h2 strong {
    line-height: normal;
    font-size: 19px;
}

.home-block p {
    font-size: 14px;
    line-height: 20px;
}


.home-block .page-content:hover h2 a, .home-block .page-content:hover p a {
    transition: none;
}


