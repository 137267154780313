/* Tools */
.hidden       { display:block !important; border:0 !important; margin:0 !important; padding:0 !important; font-size:0 !important; line-height:0 !important; width:0 !important; height:0 !important; overflow:hidden !important; }
.nobr         { white-space:nowrap !important; }
.wrap         { white-space:normal !important; }
.a-left       { text-align:left !important; }
.a-center     { text-align:center !important; }
.a-right      { text-align:right !important; }
.v-top        { vertical-align:top; }
.v-middle     { vertical-align:middle; }
.f-left,
.item-left,
.left         { float:left !important; }
.f-right,
.item-right,
.right        { float:right !important; }
.f-none       { float:none !important; }
.f-fix        { float:left; width:100%; min-height:147px; position:relative; }
.no-display   { display:none; }
.no-margin    { margin:0 !important; }
.no-padding   { padding:0 !important; }
.no-bg        { background:none !important; }

/* Flex Control */
.valign-top .grid-container {
    align-items: flex-start;
}

.valign-bottom .grid-container {
    align-items: flex-end;
}

.valign-center .grid-container {
    align-items: center;
}

.halign-top .grid-container,
.halign-top .grid-full {
    justify-content: flex-start;
}

.halign-bottom .grid-container,
.halign-bottom .grid-full {
    justify-content: flex-end;
}

.halign-center .grid-container,
.halign-center .grid-full {
    justify-content: center;
}

@media only screen and (min-width: 1280px) {
    /* Hide */
    .hide-below-1280 {
        display: inherit !important;
    }
}

/* Design for a width of less than 1024 px
%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
*/
/* width < 1024px */
@media only screen and (max-width: 1023px) {

    /* Hide */
    .hide-below-1024 { display:none !important; }
}


/* Tablet (Portrait) - design for a width of 768px
%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
*/

/* 768px <= width < 960px */
@media only screen and (min-width: 768px) and (max-width: 959px) {
    /* Hide */
    .hide-below-960 {
        display: none !important;
    }
}



/* Mobile (Landscape) - design for a width of 480px
%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
*/

/* 480px <= width < 768px */
@media only screen and (min-width: 480px) and (max-width: 767px) {
    /* Hide */
    .hide-below-960 {
        display: none !important;
    }

    .hide-below-768 {
        display: none !important;
    }
}

/* Mobile (Portrait) - design for a width of 320px
%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
*/

/* 320px <= width < 480px */
@media only screen and (min-width: 320px) and (max-width: 479px) {
    /* Hide */
    .hide-below-960 { display:none !important; }
    .hide-below-768 { display:none !important; }
    .hide-below-480 { display:none !important; }
}

/* Mobile (Portrait) - design for a width of less than 320px
%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
*/

/* width < 320px */
@media only screen and (max-width: 319px) {
    /* Hide */
    .hide-below-960 { display:none !important; }
    .hide-below-768 { display:none !important; }
    .hide-below-480 { display:none !important; }
    .hide-below-320 { display:none !important; }
}


@media only screen and (min-width: 960px) {
    .show-below-960 {
        display: none !important;
    }
}

/* Grid presentation
-------------------------------------------------------------- */
.show-grid [class*="grid12-"] {
    background-color: var(--cb-primary-light);
    text-align: center;
    min-height: 40px;
    line-height: 40px;
    transition:			all 300ms ease-in-out;
    -moz-transition:	all 300ms ease-in-out;
    -webkit-transition:	all 300ms ease-in-out;
    -o-transition:		all 300ms ease-in-out;
}
.show-grid [class*="grid12-"]:hover {
    background-color: #F08080;
}

/* Position Sticky
-------------------------------------------------------------- */
/* must have parent container with display: flex */
.cb-position-sticky {
    position: sticky;
    top: 0;
    z-index: 99;
    height: fit-content;
}