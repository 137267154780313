@use "sass:meta";
@use "colors.scss" as colors;
@use 'functions' as *;

//@import "bootstrap";
.bootstrap-styles {
  @import 'bootstrap';
}

@mixin add-css-variables($module) {
  :root {
    @each $name, $value in $module {
      --#{$name}: #{$value};
    }
  }
}


$colors: meta.module-variables('colors');

// Usages
@include add-css-variables($colors);

// After using add-css-variables($colors), You can using 1 of 2 ways below for using variable.

// Using SCSS variable
// @use "colors.scss";
// .btn-primary {
//     background: $primary;
// }
// Using CSS variable
// .btn-primary {
//     background: var(--primary);
// }

// $primary-300: hsl_l(var(--primary-dark), 40);

:root {
  --cb-global-body-font-family: var(--bs-body-font-family);

  --cb-global-font-size: 13px;
  --cb-global-font-weight: 300;
  --cb-global-font-style: normal;
  --cb-global-line-height: initial;
  --cb-global-letter-spacing: initial;

  --cb-global-icon-font-size: 1em;
  --cb-global-icon-font-weight: 400;
  --cb-global-icon-width: 36px;
  --cb-global-icon-height: 36px;
  --cb-global-icon-line-height: 36px;

  --cb-global-form-input-height: 36px;
  --cb-global-form-input-line-height: 36px;

  --cb-global-gap: 1em;
  --cb-global-margin: 1em;
  --cb-global-padding: 1em;

  --cb-global-border-width: 1px;
  --cb-global-border-color: #ccc;
  --cb-global-border-radius: 0;
  --cb-global-border: var(--cb-global-border-width) solid var(--cb-global-border-color);
  
  --cb-global-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  --cb-global-btn-height: 45px;

}


body {
  font-family: var(--cb-global-body-font-family);
  font-style: var(--cb-global-font-style);
  font-weight: var(--cb-global-font-weight);
  font-size: var(--cb-global-font-size);
  line-height: var(--cb-global-line-height);
  letter-spacing: var(--cb-global-letter-spacing);
}

.main-container {
  background-color: var(--cb-background-default);
  //display: flex;
}

/* CLEAN OVERWRITE */
.main.container {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin: 3em auto;
}

h1,
h2,
h3,
h4,
h5,
h6,
.section-title {
  text-transform: uppercase;
}

/* button */
button.button span {
  border-radius: 0;
}

.swal-button--confirm,
.swal-button--confirm:hover {
  background-color: var(--cb-secondary-main) !important;
  color: var(--secondary-contrast-text);
  border-radius: 0;
}

.price-box .minimal-price .price,
.price-box .regular-price .price,
.price-box-bundle .full-product-price .price {
  color: var(--cb-color);
  font-size: 1.4rem;
}

i.fa,
#root-wrapper span.fa {
  font-size: 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: FontAwesome !important;
  font-style: normal;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: var(--cb-global-icon-width);
  height: var(--cb-global-icon-height);
  padding: 0;
}
