// Custom.scss
@import url("https://use.typekit.net/ici3bul.css");


// Option B: Include parts of Bootstrap

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here

// these are the setting for default CB template

// $prefix: cb-;
$body-color: #fff;
$font-family-sans-serif: futura-pt, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
// $font-family-base: "League Spartan";
// $border-color: var(--default-primary-color);
// $border-radius: 0;
// $primary: var(--default-primary-color);

//
//$theme-colors: (
//        primary: $color1
//);


// 3. Include remainder of required Bootstrap stylesheets
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/variables-dark";
//$enable-dark-mode: false;

// 5. Include remainder of required parts
@import "../../node_modules/bootstrap/scss/maps";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/root";


// 6. Optionally include any other parts as needed
//@import "../node_modules/bootstrap/scss/utilities";
//@import "../node_modules/bootstrap/scss/reboot";
//@import "../node_modules/bootstrap/scss/type";
//@import "../node_modules/bootstrap/scss/images";
//@import "../node_modules/bootstrap/scss/containers";
//@import "../node_modules/bootstrap/scss/grid";
//@import "../node_modules/bootstrap/scss/helpers";
//@import "../../node_modules/bootstrap/scss/forms";
//@import "../../node_modules/bootstrap/scss/buttons";


// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
//@import "../node_modules/bootstrap/scss/utilities/api";

// 8. Add additional custom code here
$colors: (
    "blue":       $blue,
    "indigo":     $indigo,
    "purple":     $purple,
    "pink":       $pink,
    "red":        $red,
    "orange":     $orange,
    "yellow":     $yellow,
    "green":      $green,
    "teal":       $teal,
    "cyan":       $cyan,
    "white":      $white,
    "gray":       $gray-600,
    "gray-dark":  $gray-800
);